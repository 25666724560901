import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"
import { Play, Check } from "lucide-react"
import { OnboardingActionsEnum } from "~/__generated__/graphql"
import { cn } from "~/lib/utils"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { editProfilePath } from "~/common/paths"
import { useNavigate } from "react-router-dom"

interface ChecklistItemProps {
  text: string
  action: OnboardingActionsEnum
  completed?: boolean
}

const snakeToCamel = (str: string): string => {
  return str.replace(/_([a-z])/g, (match, group) => group.toUpperCase())
}

const ChecklistItem = ({
  text,
  action,
  completed = false,
}: ChecklistItemProps) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (completed) return

    switch (action) {
      case snakeToCamel(OnboardingActionsEnum.EnhanceYourProfile):
        navigate(editProfilePath.pattern)
        break
      case snakeToCamel(OnboardingActionsEnum.ReviewCommunityValues):
        console.log("Review community values action needs to be implemented")
        break
      case snakeToCamel(OnboardingActionsEnum.IntroduceYourself):
        console.log("Introduce yourself action needs to be implemented")
        break
      case snakeToCamel(OnboardingActionsEnum.DownloadOurApp):
        console.log("Download our app action needs to be implemented")
        break
      default:
        console.log(`Unknown action: ${action}`)
    }
  }

  return (
    <div
      className={cn(
        "flex items-center gap-3 p-2 border border-mercury rounded-full",
        !completed && "cursor-pointer hover:bg-gray-50"
      )}
      onClick={handleClick}
    >
      <div
        className={cn(
          "rounded-full p-1",
          completed ? "bg-success" : "bg-muted-foreground"
        )}
      >
        <Check className="h-4 w-4 text-white" />
      </div>
      <span
        className={cn(
          completed ? "" : "text-muted-foreground",
          "font-medium leading-none tracking-wide"
        )}
      >
        {text}
      </span>
    </div>
  )
}

const onboardingActionTexts: Record<string, string> = {
  [snakeToCamel(OnboardingActionsEnum.DownloadOurApp)]: "Download Our App",
  [snakeToCamel(OnboardingActionsEnum.EnhanceYourProfile)]:
    "Enhance Your Profile",
  [snakeToCamel(OnboardingActionsEnum.IntroduceYourself)]: "Introduce Yourself",
  [snakeToCamel(OnboardingActionsEnum.ReviewCommunityValues)]:
    "Review Community Values",
}

export const CTAChecklist = () => {
  const { currentUser } = useCurrentUser()

  const items = currentUser?.onboardingActions
    ? Object.entries(currentUser.onboardingActions).map(([key, completed]) => {
        return {
          action: key as OnboardingActionsEnum,
          text: onboardingActionTexts[key],
          completed: completed as boolean,
        }
      })
    : []

  return (
    <Card className="bg-[#E02B5905]">
      <CardHeader>
        <CardTitle className="flex justify-between items-center">
          <span>Get Started</span>
          <span className="flex items-center gap-1">
            <span>👋</span>
            <Play className="h-4 w-4 rounded-full border border-black p-0.5" />
          </span>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2 pt-4">
        {items.map(({ text, action, completed }) => (
          <ChecklistItem
            key={action}
            text={text}
            action={action}
            completed={completed}
          />
        ))}
      </CardContent>
    </Card>
  )
}
