import {
  CommunitySlug,
  Post_CardFragment,
  Post_CardMainDataFragment,
} from "~/__generated__/graphql"
import { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { postDateDisplay } from "./postDateDisplay"
import { PostMenu } from "./PostMenu"
import { ImageAttachments } from "./ImageAttachments"
import { FileAttachments } from "./FileAttachments"
import { PostActions } from "./PostActions"
import { gql } from "~/__generated__"
import Messages from "../images/icons/messages.svg?react"
import { PostContentSection } from "./PostContentSection"
import { createSearchParams, Link, useParams } from "react-router-dom"
import { JustBookmarkedCardHeader } from "~/bookmarks/JustBookmarkedCardHeader"
import { channelPath, postPath, userProfilePath } from "~/common/paths"
import { VideoSection } from "./VideoSection"
import { TagBubble } from "~/ui/TagBubble"
import {
  DetectInternalContentShareSection,
  RenderEmbeddedPost,
  RenderEmbeddedArticle,
} from "./PostInternalContentSection"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { filterPostAttachments } from "~/common/postUtils"
import { HighlightContent } from "~/components/HighlightContent"

import { Card, cardVariants } from "~/ui/card"
import { useUserDialogContext } from "~/directory/UserDialogContext"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/ui/tooltip"
import { cn } from "~/lib/utils"
import { useReactions } from "~/post-composer/useReactions"
import { Separator } from "~/ui/separator"
import { VariantProps } from "class-variance-authority"
import { useInView } from "react-intersection-observer"
import { useReadTracking } from "./useReadTracking"
import { usePostHighlighting } from "./usePostHighlighting"
import { useImpressions, ImpressionContentContextEnum } from "./useImpressions"
import { usePostInViewport } from "./PostInViewportContext"
import { Badge } from "~/ui/badge"
import { useCommunityClassname } from "~/community/useCommunity"
import { Poll } from "~/polls/Poll"
import { UserName } from "~/directory/UserName"
import { ComposerHandle, PostComposer } from "~/post-composer/PostComposer"
import { useCreateReply } from "~/post-composer/useCreateReply"
import { PostNestedReplies } from "./PostNestedReplies"
import { getMetaVar } from "~/common/getMetaVar"

const AUTHOR_TRUNCATION = 100
const AUTHOR_LINE_LIMIT = 1
// const CONTENT_TRUNCATION = 100
// const CONTENT_LINE_LIMIT = 3

interface PostCardProps extends VariantProps<typeof cardVariants> {
  post: Post_CardFragment | Post_CardMainDataFragment
  header?: ReactNode
  isReply?: boolean
  canReplyInLine?: boolean
  onReplyClick?: (post: Post_CardFragment | Post_CardMainDataFragment) => void
  replyDisabled?: boolean
  cardClickable?: boolean
  onSinglePostPage?: boolean
  hidePostMenu?: boolean
  hidePostActions?: boolean
  trackReadStates?: boolean
  trackImpressions?: boolean
  trackEmbedImpressions?: boolean
  query?: string
  embedVersion?: boolean
}

export const PostCard = ({
  post,
  header,
  isReply = false,
  canReplyInLine = false,
  onReplyClick,
  replyDisabled = false,
  cardClickable = false,
  onSinglePostPage = false,
  hidePostMenu = false,
  hidePostActions = false,
  query = "",
  trackReadStates = false,
  trackImpressions = false,
  trackEmbedImpressions = false,
  embedVersion = false,
  ...cardVariantProps
}: PostCardProps) => {
  const visuallyShowImpressions =
    getMetaVar("visually-show-impressions") === "true"

  const [replyComposerOpen, setReplyComposerOpen] = useState(false)
  const { channelSlug } = useParams()
  const [imageAttachments, otherAttachments] = filterPostAttachments(post)
  const { openUserDialog } = useUserDialogContext()
  const { addReaction, removeReaction } = useReactions({ postId: post.id })
  const [showJustBookmarkedHeader, setShowJustBookmarkedHeader] =
    useState(false)
  const { updateInView } = usePostInViewport() || {}
  const ccls = useCommunityClassname()
  const composerRef = useRef<ComposerHandle>(null)
  const [nestedRepliesExpanded, setNestedRepliesExpanded] = useState(false)

  const { createReply, replayIsSaving } = useCreateReply({
    parentPostId: post.parentPost?.id,
    articleId: post.article?.id,
    parentReplyId: post.id,
    onSuccess: () => {
      setReplyComposerOpen(false)
      setNestedRepliesExpanded(false)
    },
  })
  const replyCb = useCallback(
    (post_: Post_CardFragment | Post_CardMainDataFragment) => {
      const mentionString = `@[${post_.user.name}](${post_.user.id}) `
      setReplyComposerOpen(true)
      setTimeout(() => {
        composerRef.current?.prefillContent(mentionString)
      }, 250)
    },
    []
  )
  const replyToReply = onReplyClick || replyCb

  const elementRef = useRef<HTMLDivElement | null>(null)

  const { inView, ref: inViewRef } = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  })
  useReadTracking({ inView, post, enabled: trackReadStates })
  const { isHighlighted } = usePostHighlighting({
    inView,
    post,
    enabled: trackReadStates,
  })
  const { impressionMade } = useImpressions({
    inView,
    content: {
      type: "post",
      content: post,
      context: embedVersion
        ? ImpressionContentContextEnum.Embed
        : ImpressionContentContextEnum.Standalone,
    },
    enabled: trackImpressions,
    elementRef: elementRef,
  })

  const setRefs = useCallback(
    (node: HTMLDivElement | null) => {
      elementRef.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  useEffect(() => {
    updateInView && updateInView({ post, inView })
  }, [inView, post, updateInView])

  if (post.deleted) {
    return (
      <Card
        {...cardVariantProps}
        className={cn(
          isHighlighted && "border-highlight bg-card-highlight-background",
          visuallyShowImpressions &&
            impressionMade &&
            "border-l-2 border-r-2 border-l-purple-500 border-r-purple-500"
        )}
      >
        <div className="p-4 text-2xs italic tracking-wide text-[#666666]">
          This post has been deleted
        </div>
      </Card>
    )
  }

  return (
    <>
      <Card
        {...cardVariantProps}
        ref={setRefs}
        className={cn(
          "overflow-visible",
          isHighlighted && "border-highlight bg-card-highlight-background",
          visuallyShowImpressions &&
            impressionMade &&
            "border-l-2 border-r-2 border-l-purple-500 border-r-purple-500"
        )}
      >
        {showJustBookmarkedHeader && <JustBookmarkedCardHeader />}
        {header}
        <div className="flex p-4">
          <div
            onClick={() => openUserDialog(post.user.id)}
            className="mr-3 card-clickable cursor-pointer shrink-0 flex flex-col items-center"
          >
            <AvatarWithFallback user={post.user} />
            {isReply && post.nestedRepliesCount >= 2 && <ReplyHook />}
          </div>

          <div className="flex flex-col gap-4 w-full">
            <div className="flex items-center">
              <Link
                to={userProfilePath({ id: post.user.id })}
                className="text-sm tracking-wide font-semibold mt-0.5 mr-2 card-clickable hover:underline flex gap-2 items-center"
                onClick={(e) => {
                  e.preventDefault()
                  openUserDialog(post.user.id)
                }}
              >
                <UserName
                  user={post.user}
                  formatter={(userName) => (
                    <HighlightContent
                      query={query}
                      content={userName}
                      truncationLimit={AUTHOR_TRUNCATION}
                      lineLimit={AUTHOR_LINE_LIMIT}
                    />
                  )}
                />
              </Link>
              <div className="ml-auto text-xs tracking-wide text-gray-400">
                {postDateDisplay(post.createdAt)}
              </div>
              {!hidePostMenu && (
                <div className="ml-2 flex flex-col justify-center">
                  <PostMenu post={post} onSinglePostPage={onSinglePostPage} />
                </div>
              )}
            </div>

            {post.channel && (
              <div className="flex">
                {post.channel.active ? (
                  <Link to={channelPath({ channelSlug: post.channel.slug })}>
                    <Badge
                      variant="channel"
                      className={cn(
                        "card-clickable",
                        ccls({
                          [CommunitySlug.Marketingland]:
                            "text-marketingland-blue border-marketingland-blue hover:bg-marketingland-blue/10",
                          default: "hover:bg-highlight/10",
                        })
                      )}
                    >
                      <Messages height="14px" width="14px" />
                      {post.channel.name}
                    </Badge>
                  </Link>
                ) : (
                  <Badge
                    variant="channel"
                    className={cn(
                      ccls({
                        [CommunitySlug.Marketingland]:
                          "text-marketingland-blue border-marketingland-blue",
                        default: "",
                      })
                    )}
                  >
                    <Messages height="14px" width="14px" />
                    {post.channel.name}
                  </Badge>
                )}
              </div>
            )}

            <PostContentSection post={post} />
            <VideoSection videoUrl={post.videoUrl} />
            <ImageAttachments attachments={imageAttachments} />
            <FileAttachments attachments={otherAttachments} />
            {post.retweetPostId && (
              <RenderEmbeddedPost
                postId={post.retweetPostId}
                parentPost={post}
                trackImpressions={trackImpressions || trackEmbedImpressions}
              />
            )}
            {post.retweetArticleId && (
              <RenderEmbeddedArticle
                articleId={post.retweetArticleId}
                parentPost={post}
                trackImpressions={trackImpressions || trackEmbedImpressions}
              />
            )}
            <DetectInternalContentShareSection
              post={post}
              trackImpressions={trackImpressions || trackEmbedImpressions}
            />

            {post.poll && (
              <>
                <Separator />
                <Poll poll={post.poll} className="card-clickable" />
              </>
            )}

            {post.tag && (
              <div>
                <TagBubble tagName={post.tag.name} />
              </div>
            )}

            {post.reactionEntries.length > 0 && (
              <div className="flex gap-2">
                {post.reactionEntries.map((entry) => (
                  <TooltipProvider key={entry.id}>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger>
                        <div
                          className={cn(
                            "rounded-full border border-mercury p-2 flex items-center text-2xs cursor-pointer card-clickable",
                            {
                              "border-blue-500 bg-blue-50":
                                entry.currentUserReacted,
                            }
                          )}
                          onClick={(e) => {
                            entry.currentUserReacted
                              ? removeReaction(entry.emoji)
                              : addReaction(entry.emoji)
                          }}
                        >
                          {entry.emoji}
                          <span className="ml-1">{entry.count}</span>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        {entry.userNames.map((name, index) => (
                          <p key={index} className="text-center">
                            {name}
                          </p>
                        ))}
                        {entry.userNames.length < entry.count && (
                          <p className="text-center">...and others</p>
                        )}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ))}
              </div>
            )}

            {!hidePostActions && (
              <>
                {!isReply && <Separator />}

                <PostActions
                  post={post}
                  isReply={isReply}
                  replyDisabled={replyComposerOpen || replyDisabled}
                  replyToReply={() => replyToReply(post)}
                  onSinglePostPage={onSinglePostPage}
                  onBookmarkToggle={(bookmarkExists) =>
                    setShowJustBookmarkedHeader(bookmarkExists)
                  }
                />
              </>
            )}

            {canReplyInLine && replyComposerOpen && (
              <PostComposer
                onSave={createReply}
                isSaving={replayIsSaving}
                mentionsInputPlaceholder={""}
                isReply
                ref={composerRef}
                withDirtyTracking={false}
              />
            )}

            {hasNestedReplies(post) && (
              <PostNestedReplies
                post={post}
                expanded={nestedRepliesExpanded}
                setExpanded={setNestedRepliesExpanded}
                onReplyClick={replyToReply}
              />
            )}
          </div>
        </div>

        {cardClickable && (
          <Link
            to={{
              pathname: postPath({ postId: post.id }),
              search: channelSlug
                ? createSearchParams({ from: "channel" }).toString()
                : "",
            }}
            state={{ useBrowserBack: true }}
            className="absolute top-0 left-0 right-0 bottom-0"
          />
        )}
      </Card>
    </>
  )
}

const hasNestedReplies = (
  t: Post_CardFragment | Post_CardMainDataFragment
): t is Post_CardFragment => {
  return (t as Post_CardFragment).mostRecentNestedReply !== undefined
}

const ReplyHook = () => (
  <>
    <div className="bg-white flex-1 w-[1px] border-l border-black mt-2 mb-4 relative">
      <div className="absolute bottom-0 left-[calc(50%-1px)] w-[11px] h-[11px] bg-white" />
      <div className="absolute bottom-0 left-[calc(50%-1px)] w-[11px] h-[11px] rounded-bl-full border-b border-l border-black" />
    </div>
  </>
)

gql(`
  fragment Post_Card on Post {
    ...Post_CardMainData

    mostRecentNestedReply {
      ...Post_CardMainData
    }
  }
`)

gql(`
  fragment Post_CardMainData on Post {
    id
    content
    createdAt
    isReply
    repliesCount
    nestedRepliesCount
    pinnable
    videoUrl
    retweetPostId
    retweetArticleId
    currentUserUpvoted
    currentUserFlagged
    currentUserBookmark {
      ...Bookmark
    }
    currentUserCanDestroy {
      value
    }
    currentUserCanFlag {
      value
    }
    currentUserCanUpdate {
      value
    }
    currentUserCanPin {
      value
    }
    deleted
    parentPost {
      id
    }
    article {
      id
      approvedRevision {
        id
        title
      }
    }
    user {
      ...User_Avatar
    }
    channel {
      id
      name
      slug
      active
    }
    tag {
      id
      name
    }
    attachments {
      id
      editorUrl
      contentType
      byteSize
      filename
    }
    currentUserHasRead
    orderedCommenters {
      ...User_Avatar
    }
    commentersCount
    retweetersCount
    shareCount
    reactionEntries {
      id
      emoji
      count
      userNames
      currentUserReacted
    }
    poll {
      ...PollDisplay
    }
  }
`)

export const POST_INCREMENT_SHARE_COUNT_MUTATION = gql(`
  mutation PostIncrementShareCount($input: PostIncrementShareCountInput!) {
    postIncrementShareCount(input: $input) {
      post {
        id
        shareCount
      }
    }
  }
`)
