import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { AttachmentTypeEnum } from "~/__generated__/graphql"
import { directImageUpload } from "~/common/directImageUpload"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import mime from "mime"
import { AttachmentCreateMutation } from "~/__generated__/graphql"
import { DirectUploadDelegate } from "@rails/activestorage"

type EditorJsImageResponseType = {
  id: string
  url: string
  size: number
  name: string
  contentType: string
  extension: string | null
}

export const useFileUpload = ({ articleId }: { articleId?: string } = {}) => {
  const [runAttachFile] = useSafeMutation(ATTACH_FILE_MUTATION)

  const attachFile = async (
    fileSignedId: string,
    attachmentType: AttachmentTypeEnum,
    isOnboardingContent: boolean = false
  ): Promise<
    [EditorJsImageResponseType | null, AttachmentCreateMutation | null]
  > => {
    const { data, errors } = await runAttachFile({
      variables: {
        input: { articleId, fileSignedId, attachmentType, isOnboardingContent },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
      return [null, null]
    }

    const attachment = data?.attachmentCreate.attachment
    invariant(attachment)

    return [
      {
        id: attachment.id,
        url: attachment.editorUrl,
        size: attachment.byteSize,
        contentType: attachment.contentType,
        name: attachment.filename,
        extension: mime.getExtension(attachment.contentType),
      },
      data,
    ]
  }

  const uploadAndAttach = async (
    file: File,
    attachmentType: AttachmentTypeEnum,
    progressDelegate?: DirectUploadDelegate,
    isOnboardingContent: boolean = false
  ) => {
    const directUploadJson = await directImageUpload(file, progressDelegate)
    const { signedId } = directUploadJson

    const [, data] = await attachFile(
      signedId,
      attachmentType,
      isOnboardingContent
    )
    return data
  }

  const uploadAndAttachImage = async (file: File) => {
    const directUploadJson = await directImageUpload(file)
    const { signedId } = directUploadJson

    const [fileDetails] = await attachFile(signedId, AttachmentTypeEnum.Image)

    return {
      success: 1,
      file: fileDetails,
    }
  }

  const uploadAndAttachFile = async (file: File) => {
    const directUploadJson = await directImageUpload(file)
    const { signedId } = directUploadJson

    const [fileDetails] = await attachFile(signedId, AttachmentTypeEnum.File)

    return {
      success: 1,
      file: fileDetails,
    }
  }

  return { uploadAndAttachImage, uploadAndAttachFile, uploadAndAttach }
}

const ATTACH_FILE_MUTATION = gql(`
  mutation AttachmentCreate($input: AttachmentCreateInput!) {
    attachmentCreate(input: $input) {
      attachment {
        id
        editorUrl
        contentType
        byteSize
        filename
      }
    }
  }
`)
