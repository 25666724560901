import { Info, SatelliteDish } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"
import { Events } from "~/admin/Events"
import { adminSailthruSyncsPath } from "~/common/paths"
import { Alert, AlertDescription, AlertTitle } from "~/ui/alert"
import { Link } from "~/ui/Link"

export const AdminEventsScreen = () => {
  return (
    <>
      <AdminHeader title="Ahoy Events" Icon={SatelliteDish} />
      <Alert variant="info" className="mb-4">
        <Info className="w-4 h-4 mr-2" />
        <AlertTitle>Looking for Sailthru syncs?</AlertTitle>
        <AlertDescription>
          Check out the new{" "}
          <Link to={adminSailthruSyncsPath.pattern}>Sailthru Syncs</Link> page.
        </AlertDescription>
      </Alert>
      <Events />
    </>
  )
}
