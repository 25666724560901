import { useEffect, useState } from "react"
import { Dialog, DialogContent } from "~/ui/dialog"
import { Button } from "~/ui/button"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { gql } from "~/__generated__"
import { useLazyQuery } from "@apollo/client"
import AsyncSelect from "react-select/async"
import { useCallback } from "react"
import { MENTION_USER_QUERY_DOCUMENT } from "~/post-composer/useMentionDataSource"
import { useDebouncedCallback } from "use-debounce"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import {
  AhoyEventTypeEnum,
  ArticleCollaboratorsModalQuery,
  User_ArticleCollaboratorModalFragment,
} from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { UserPill } from "~/ui/UserPill"
import { useCommunity } from "~/community/useCommunity"
import {
  SavedOrUnsavedArticleCollaborator,
  isSavedArticleCollaborator,
} from "~/types"

type UserOption = {
  value: User_ArticleCollaboratorModalFragment
  label: string
}

export const useCreateCollaborator = () => {
  const [runCollaboratorCreate, collaboratorCreateResult] = useSafeMutation(
    COLLABORATOR_CREATE_MUTATION
  )

  const createCollaborator = useCallback(
    async (
      users:
        | User_ArticleCollaboratorModalFragment
        | User_ArticleCollaboratorModalFragment[],
      articleId?: string
    ) => {
      if (!articleId) {
        return
      }

      const userArray = Array.isArray(users) ? users : [users]
      const userIds = userArray.map((user) => user.id)

      const { data, errors } = await runCollaboratorCreate({
        variables: {
          input: {
            userIds,
            articleId,
          },
        },
      })

      if (errors) {
        displayErrors(errors)
        console.log(errors)
      }

      return data
    },
    [runCollaboratorCreate]
  )

  return { createCollaborator, collaboratorCreateResult }
}

export const useDestroyCollaborator = () => {
  const [runCollaboratorDestroy, collaboratorDestroyResult] = useSafeMutation(
    COLLABORATOR_DESTROY_MUTATION
  )
  const destroyCollaborator = useCallback(
    async (user: User_ArticleCollaboratorModalFragment, articleId?: string) => {
      if (!articleId) {
        return
      }

      const { errors } = await runCollaboratorDestroy({
        variables: {
          input: {
            userId: user.id,
            articleId,
          },
        },
      })

      if (errors) {
        displayErrors(errors)
        console.log(errors)
      }
    },
    [runCollaboratorDestroy]
  )

  return { destroyCollaborator, collaboratorDestroyResult }
}

export const ArticleCollaboratorsModal = ({
  setIsOpen,
  articleId,
  isEditing = false,
  collaborators,
  includeCollaborators = [],
  onSubmit,
  onAddCollaborator,
  onRemoveCollaborator,
}: {
  setIsOpen: (value: boolean) => void
  articleId?: string
  isEditing?: boolean
  collaborators?: SavedOrUnsavedArticleCollaborator[]
  includeCollaborators?: string[]
  onSubmit?: (
    collaborators: SavedOrUnsavedArticleCollaborator[],
    articleId?: string
  ) => void
  onAddCollaborator?: (
    user: User_ArticleCollaboratorModalFragment,
    articleId?: string
  ) => void
  onRemoveCollaborator?: (
    user: User_ArticleCollaboratorModalFragment,
    articleId?: string
  ) => void
}) => {
  const [getArticleCollaborators] = useLazyQuery(
    ARTICLE_COLLABORATORS_MODAL_QUERY_DOCUMENT
  )

  const [data, setData] = useState<ArticleCollaboratorsModalQuery | null>(null)

  useEffect(() => {
    async function fetchData() {
      if (articleId) {
        const { data } = await getArticleCollaborators({
          variables: { articleId },
        })
        setData(data || null)
      }
    }

    if (!collaborators) {
      fetchData()
    }
  }, [articleId, getArticleCollaborators, collaborators])

  const community = useCommunity()

  const [currentCollaborators, setCurrentCollaborators] = useState<
    SavedOrUnsavedArticleCollaborator[]
  >([])

  useEffect(() => {
    if (data?.article) {
      setCurrentCollaborators(data.article.collaborators)
    }
  }, [data])

  useEffect(() => {
    if (collaborators && collaborators.length > 0) {
      setCurrentCollaborators(collaborators)
    }
  }, [collaborators])

  const handleAddCollaborator = useCallback(
    (user: User_ArticleCollaboratorModalFragment) => {
      setCurrentCollaborators((collaborators) => {
        if (collaborators.some((c) => c.user.id === user.id)) {
          return collaborators
        }

        return [
          ...collaborators,
          {
            tempId: user.id,
            user: {
              ...user,
            },
            pending: true,
            currentUserCanDestroy: {
              value: true,
            },
          },
        ]
      })
    },
    []
  )

  const handleRemoveCollaborator = useCallback(
    (user: User_ArticleCollaboratorModalFragment) => {
      setCurrentCollaborators((collaborators) => {
        if (!collaborators) return []

        return collaborators.filter((c) => c.user.id !== user.id)
      })

      if (onRemoveCollaborator) {
        onRemoveCollaborator(user, articleId)
      }
    },
    [onRemoveCollaborator, articleId]
  )

  const [getCollaboratorSearch] = useLazyQuery(MENTION_USER_QUERY_DOCUMENT, {
    variables: { excludeSelf: false },
  })

  const _loadOptions = useCallback(
    (query: string, callback: (options: UserOption[]) => void) => {
      getCollaboratorSearch({
        variables: { query },
      }).then(({ data }) => {
        if (!data) {
          callback([])
        } else {
          callback(
            data.users.nodes.map((u) => ({
              value: u,
              label: u.name || "",
            }))
          )
        }
      })
    },
    [getCollaboratorSearch]
  )
  const loadOptions = useDebouncedCallback(_loadOptions, 400)

  const [fetchUser] = useLazyQuery(USER_FETCH_QUERY_DOCUMENT, {
    fetchPolicy: "network-only",
  })

  useEffect(() => {
    if (includeCollaborators.length > 0) {
      includeCollaborators.forEach(async (userId) => {
        const { data } = await fetchUser({ variables: { userId } })
        if (data?.user) {
          handleAddCollaborator(data.user)
        }
      })
    }
  }, [includeCollaborators, fetchUser, handleAddCollaborator])

  const { createCollaborator } = useCreateCollaborator()
  const { logEvent } = useLogEvent()
  const { currentUser } = useCurrentUser()

  const onButtonClick = useCallback(async () => {
    if (!articleId) return

    const newCollaborators = currentCollaborators.filter(
      (collaborator) => collaborator.user.id !== currentUser.id
    )

    if (newCollaborators.length !== 0) {
      const result = await createCollaborator(
        newCollaborators.map((c) => c.user),
        articleId
      )

      if (result?.collaboratorCreate?.article?.collaborators) {
        const collaboratorUserIds =
          result.collaboratorCreate.article.collaborators.map(
            (c) => c.user.id
          ) || []

        collaboratorUserIds.forEach((collaboratorId) => {
          const collaborator = newCollaborators.find((c) =>
            isSavedArticleCollaborator(c)
              ? c.id === collaboratorId
              : c.tempId === collaboratorId
          )
          if (collaborator) {
            logEvent(AhoyEventTypeEnum.CollaborationInviteSent, {
              content_id: articleId,
              user_id: collaborator.user.id,
            })
          }
        })
      }
    }

    if (onSubmit) {
      onSubmit(currentCollaborators || [], articleId)
    }
  }, [
    onSubmit,
    articleId,
    currentCollaborators,
    createCollaborator,
    logEvent,
    currentUser,
  ])

  return (
    <Dialog
      open
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="text-center mb-2 font-serif text-3xl">
          Invite Collaborators
        </div>
        <div className="text-center mb-6 text-2xs">
          Work on this content with {community.name} peers. They'll receive an
          invite to collaborate with you, get edit access, and be listed as a
          co-author when you're ready to publish.
        </div>

        <div>
          <div className="flex mb-4 gap-4">
            <AsyncSelect
              classNamePrefix="select"
              className="flex-1"
              placeholder="Find a collaborator..."
              loadOptions={loadOptions}
              value={null}
              onChange={(selection) => {
                if (selection?.value) {
                  handleAddCollaborator(selection.value)
                }
              }}
              styles={{
                control: (baseStyles, _state) => ({
                  ...baseStyles,
                  outline: "none !important",
                  boxShadow: "none",
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingLeft: 12,
                  fontSize: 16,
                  borderRadius: 8,
                }),
              }}
            />
          </div>

          <div className="flex flex-wrap gap-3">
            {currentCollaborators &&
              currentCollaborators
                .toReversed()
                .map((c) => (
                  <UserPill
                    key={isSavedArticleCollaborator(c) ? c.id : c.tempId}
                    user={c.user}
                    canRemove={c.currentUserCanDestroy.value}
                    onRemove={() => handleRemoveCollaborator(c.user)}
                    pending={c.pending}
                  />
                ))}
          </div>

          <div className="h-[1px] bg-gray-300 my-8" />

          <div className="flex justify-end">
            <Button type="submit" className="px-10" onClick={onButtonClick}>
              {isEditing ? "Submit" : "Invite Collaborators & Continue"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

gql(`
  fragment User_ArticleCollaboratorModal on User {
    id
    name
    ...User_Avatar
  }
`)

gql(`
  fragment ArticleCollaborator_AddModal on ArticleCollaborator {
    id
    pending
    currentUserCanDestroy {
      value
    }
    user {
      ...User_ArticleCollaboratorModal
    }
  }
`)

gql(`
  fragment Article_AddCollaboratorModal on Article {
    id
    collaborators(includePending: true) {
      ...ArticleCollaborator_AddModal
    }
  }
`)

const ARTICLE_COLLABORATORS_MODAL_QUERY_DOCUMENT = gql(`
  query ArticleCollaboratorsModal($articleId: ID!) {
    article(articleId: $articleId) {
      ...Article_AddCollaboratorModal
    }
  }
`)

const COLLABORATOR_CREATE_MUTATION = gql(`
  mutation CollaboratorCreate($input: CollaboratorCreateInput!) {
    collaboratorCreate(input: $input) {
      article {
        ...Article_AddCollaboratorModal
      }
    }
  }
`)

const COLLABORATOR_DESTROY_MUTATION = gql(`
  mutation CollaboratorDestroy($input: CollaboratorDestroyInput!) {
    collaboratorDestroy(input: $input) {
      article {
        ...Article_AddCollaboratorModal
      }
    }
  }
`)

const USER_FETCH_QUERY_DOCUMENT = gql(`
  query ArticleCollaboratorModalUserFetch($userId: ID!) {
    user(userId: $userId) {
      ...User_ArticleCollaboratorModal
    }
  }
`)
