import { useEffect } from "react"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { Button } from "~/ui/button"
import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/ui/dialog"
import { useWizard } from "~/ui/Wizard"
import { LoadingStep } from "./LoadingStep"
import toast from "react-hot-toast"
import { Translation } from "~/common/Translation"
import { Backpack, LineChart, Smile, Siren } from "lucide-react"
import { HeartHandshake } from "lucide-react"
import { Heart } from "lucide-react"
import { Mic } from "lucide-react"
import { feedPath } from "~/common/paths"
import { displayErrors } from "~/common/validations"
import { AhoyEventTypeEnum, OnboardingStateEnum } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { useNavigate } from "react-router-dom"
import { useLogEvent } from "~/analytics/EventsContext"
import { NewMembershipCard } from "~/users/NewMembershipCard"

export const SocialContractStep = () => {
  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()
  const { close, goToStep } = useWizard()
  const { logEvent } = useLogEvent()
  const [runUserUpdate, { loading }] = useSafeMutation(USER_UPDATE_MUTATION)

  console.log(currentUser)

  useEffect(() => {
    if (!currentUser.activeStripeSubscription) {
      console.error("SocialContractStep: No activeStripeSubscription found")
      toast.error("Something went wrong. Please try again.")
      goToStep("PricingTableStep", "forward", false)
    }
  }, [currentUser.activeStripeSubscription, goToStep])

  const onComplete = async () => {
    const { errors } = await runUserUpdate({
      variables: {
        input: {
          onboardingState: OnboardingStateEnum.Completed,
        },
      },
    })
    if (errors) {
      displayErrors(errors)
    } else {
      logEvent(AhoyEventTypeEnum.OnboardingCompleted)
      close()
      navigate(feedPath.pattern)
    }
  }

  if (!currentUser.activeStripeSubscription) return <LoadingStep />

  return (
    <>
      <div className="flex justify-center my-4">
        <NewMembershipCard />
      </div>
      <DialogHeader>
        <DialogTitle>
          <Translation
            ns="socialContractStep"
            i18nKey="title"
            values={{ tierName: currentUser.tier?.name }}
          />
        </DialogTitle>
      </DialogHeader>
      <DialogDescription className="text-center">
        <Translation ns="socialContractStep" i18nKey="body" />
      </DialogDescription>
      <ValuesGrid />
      <DialogFooter className="justify-center">
        <Button
          className="bg-highlight text-white text-xs px-6 py-4"
          onClick={onComplete}
          disabled={loading}
        >
          <Translation ns="socialContractStep" i18nKey="confirmText" />
        </Button>
      </DialogFooter>
    </>
  )
}

SocialContractStep.displayName = "SocialContractStep"

const ValuesGrid = () => (
  <>
    <div className="grid grid-rows-1 md:grid-rows-2 grid-flow-row md:grid-flow-col auto-cols-fr gap-4 text-white text-sm font-medium mt-6">
      <Translation
        ns="socialContractStep"
        i18nKey="values"
        components={{
          BackpackValue: <BackpackValue />,
          ChartLineValue: <ChartLineValue />,
          HeartHandshakeValue: <HeartHandshakeValue />,
          HeartValue: <HeartValue />,
          MicrophoneValue: <MicrophoneValue />,
          SirenValue: <SirenValue />,
          SmileyValue: <SmileyValue />,
        }}
      />
    </div>

    <div className="text-xs text-center my-4 md:my-8">
      <Translation ns="socialContractStep" i18nKey="disclaimer" />
    </div>
  </>
)

const SocialContractValue = ({
  children,
  icon,
}: {
  children?: React.ReactNode
  icon?: React.ReactNode
}) => {
  return (
    <div className="bg-highlight rounded-lg px-4 py-6 md:px-6 md:py-9 text-white text-xs flex items-center">
      <div className="flex items-center gap-4">
        {icon}
        <div className="text-start max-w-[180px]">{children}</div>
      </div>
    </div>
  )
}

const MicrophoneValue = ({ children }: { children?: React.ReactNode }) => (
  <SocialContractValue icon={<Mic strokeWidth={1} />}>
    {children}
  </SocialContractValue>
)

const HeartValue = ({ children }: { children?: React.ReactNode }) => (
  <SocialContractValue icon={<Heart />}>{children}</SocialContractValue>
)

const HeartHandshakeValue = ({ children }: { children?: React.ReactNode }) => (
  <SocialContractValue icon={<HeartHandshake strokeWidth={1} />}>
    {children}
  </SocialContractValue>
)

const BackpackValue = ({ children }: { children?: React.ReactNode }) => (
  <SocialContractValue icon={<Backpack strokeWidth={1} className="w-6 h-6" />}>
    {children}
  </SocialContractValue>
)

const ChartLineValue = ({ children }: { children?: React.ReactNode }) => (
  <SocialContractValue icon={<LineChart strokeWidth={1} />}>
    {children}
  </SocialContractValue>
)

const SmileyValue = ({ children }: { children?: React.ReactNode }) => (
  <SocialContractValue icon={<Smile strokeWidth={1} />}>
    {children}
  </SocialContractValue>
)

const SirenValue = ({ children }: { children?: React.ReactNode }) => (
  <SocialContractValue icon={<Siren strokeWidth={1} />}>
    {children}
  </SocialContractValue>
)
