import { useQuery } from "@apollo/client"
import { Hammer } from "lucide-react"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { ScreenVariantCreateInput } from "~/__generated__/graphql"
import { AdminHeader } from "~/admin/AdminHeader"
import { ScreenVariantForm } from "~/admin/ScreenVariantForm"
import { adminScreenVariantsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"

export const AdminScreenVariantEditScreen = () => {
  const [runUpdateScreenVariant] = useSafeMutation(
    SCREEN_VARIANT_UPDATE_MUTATION
  )
  const navigate = useNavigate()
  const { screenVariantId } = useParams()
  const { data } = useQuery(SCREEN_VARIANT_QUERY_DOCUMENT, {
    variables: { id: screenVariantId! },
    skip: !screenVariantId,
  })

  const handleSubmit = async (data: ScreenVariantCreateInput) => {
    const response = await runUpdateScreenVariant({
      variables: {
        input: {
          id: screenVariantId!,
          ...data,
        },
      },
    })

    const { data: responseData, errors } = response

    if (!errors && responseData?.screenVariantUpdate?.screenVariant) {
      toast.success("Screen variant updated.")
      navigate(adminScreenVariantsPath.pattern)
    }

    return response
  }

  const screenVariant = data?.screenVariant

  return (
    <div className="pb-12">
      <AdminHeader title="Edit Screen Variant" subtitle="" Icon={Hammer} />

      {screenVariant && (
        <ScreenVariantForm
          onSubmit={handleSubmit}
          initialValues={screenVariant}
        />
      )}
    </div>
  )
}

const SCREEN_VARIANT_QUERY_DOCUMENT = gql(`
  query ScreenVariant($id: ID!) {
    screenVariant(id: $id) {
      ...ScreenVariant_Admin
    }
  }
`)

const SCREEN_VARIANT_UPDATE_MUTATION = gql(`
  mutation ScreenVariantUpdate($input: ScreenVariantUpdateInput!) {
    screenVariantUpdate(input: $input) {
      screenVariant {
        ...ScreenVariant_Admin
      }
    }
  }
`)
