import * as React from "react"
import { RouterProvider } from "react-router-dom"
import { Toaster } from "react-hot-toast"
import { router } from "./router"
import colors from "tailwindcss/colors"
import { Worker } from "@react-pdf-viewer/core"

import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import "react-toggle/style.css"
import "./common/i18n"

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client"
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev"
import { createConsumer } from "@rails/actioncable"
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink"
import { getMetaVar } from "./common/getMetaVar"
import { FlashMessageToasts } from "./ui/FlashMessageToasts"
import { relayStylePagination } from "@apollo/client/utilities"
import { KeyArgsFunction } from "@apollo/client/cache/inmemory/policies"
import { CommunityProvider } from "./community/useCommunity"
import { CURRENT_USER_QUERY_DOCUMENT } from "./auth/currentUserQuery"
import { COMMUNITY_QUERY_DOCUMENT } from "./community/communityQuery"
import { communityData } from "./community"
import { currentUserData } from "./auth"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

// @ts-expect-error this is a vite-only feature
if (import.meta.env.DEV) {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

const cable = createConsumer()

const hasSubscriptionOperation = ({ query: { definitions } }: any) => {
  return definitions.some(
    ({ kind, operation }: any) =>
      kind === "OperationDefinition" && operation === "subscription"
  )
}

const csrfToken = getMetaVar("csrf-token")

if (csrfToken == null) {
  console.warn("missing csrf token")
}

const httpLink = new HttpLink({
  credentials: "same-origin",
  headers: {
    "X-CSRF-Token": csrfToken!,
  },
})

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
)

const excludePaginationArgs: KeyArgsFunction = (args) => {
  if (!args) return false
  return Object.keys(args).filter((k) => !["first", "after"].includes(k))
}

const apolloClient = new ApolloClient({
  uri: "/graphql",
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          posts: relayStylePagination(excludePaginationArgs),
          users: relayStylePagination(excludePaginationArgs),
          libraryArticles: relayStylePagination(excludePaginationArgs),
          notifications: relayStylePagination(excludePaginationArgs),
          bookmarks: relayStylePagination(excludePaginationArgs),
          introductionCycles: relayStylePagination(excludePaginationArgs),
          myRooms: relayStylePagination(excludePaginationArgs),
          events: relayStylePagination(excludePaginationArgs),
          places: relayStylePagination(excludePaginationArgs),
          proEvents: relayStylePagination(excludePaginationArgs),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
})

apolloClient.writeQuery({
  query: CURRENT_USER_QUERY_DOCUMENT,
  data: currentUserData.data,
  variables: { id: currentUserData.data.user?.id },
})
apolloClient.writeQuery({
  query: COMMUNITY_QUERY_DOCUMENT,
  data: communityData.data,
  variables: { id: communityData.data.community!.id },
})

const stripePublishableKey = getMetaVar("stripe-publishable-key")
const stripePromise = loadStripe(stripePublishableKey)

export const App = () => {
  return (
    <React.StrictMode>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <ApolloProvider client={apolloClient}>
          <Elements stripe={stripePromise}>
            <CommunityProvider>
              <Toaster
                toastOptions={{
                  duration: 4000,
                  style: {
                    maxWidth: "100%",
                    textAlign: "left",
                  },
                  success: {
                    style: {
                      color: colors.white,
                      backgroundColor: colors.green[700],
                    },
                  },
                  error: {
                    style: {
                      color: colors.white,
                      backgroundColor: colors.red[700],
                    },
                  },
                }}
              />
              <FlashMessageToasts />
              <RouterProvider router={router} />
            </CommunityProvider>
          </Elements>
        </ApolloProvider>
      </Worker>
    </React.StrictMode>
  )
}
