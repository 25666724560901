import { WaitingRoomHeader } from "../ABFitWaitingScreen"
import { ProcessingCTAs } from "./ProcessingCTAs"

export const Processing = () => {
  return (
    <div className="flex items-center flex-col">
      <WaitingRoomHeader />
      <ProcessingCTAs />
    </div>
  )
}
