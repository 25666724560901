import { useMutation } from "@apollo/client"
import { useCallback } from "react"
import { gql } from "~/__generated__"
import {
  UserExperimentAssignVariantMutation,
  UserExperimentAssignVariantMutationVariables,
  AhoyEventTypeEnum,
} from "~/__generated__/graphql"
import { getMetaVar } from "~/common/getMetaVar"
import { useLogEvent } from "~/analytics/EventsContext"

const qaToolsEnabled = getMetaVar("qa-tools-enabled") === "true"

export function useExperiment(experimentName: string) {
  const [assignVariant] = useMutation<
    UserExperimentAssignVariantMutation,
    UserExperimentAssignVariantMutationVariables
  >(ASSIGN_EXPERIMENT)

  const { logEvent } = useLogEvent()

  const getOrAssignVariant = useCallback(async () => {
    const { data } = await assignVariant({
      variables: {
        input: { experiment: experimentName },
      },
    })

    const experimentResult = data?.userExperimentAssignVariant
    const variant = experimentResult?.experiment?.variant
    const wasCreated = experimentResult?.wasCreated

    if (qaToolsEnabled) {
      console.log(
        `[analytics] - Experiment ${experimentName} variant ${
          wasCreated ? "created" : "used"
        }:`,
        variant
      )
    }

    if (wasCreated) {
      logEvent(AhoyEventTypeEnum.TestVariantAssigned, {
        test_name: experimentName,
        variant: variant,
      })
    }

    return { variant, wasCreated }
  }, [assignVariant, experimentName, logEvent])

  return { getOrAssignVariant }
}

const ASSIGN_EXPERIMENT = gql(`
  mutation UserExperimentAssignVariant(
    $input: UserExperimentAssignVariantInput!
  ) {
    userExperimentAssignVariant(input: $input) {
      experiment {
        name
        variant
      }
      wasCreated
    }
  }
`)
