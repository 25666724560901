import clsx from "clsx"
import toast from "react-hot-toast"
import { useState, useEffect, useRef } from "react"
import ArrowRight from "../images/icons/arrow-right.svg?react"
import Cup from "../images/icons/cup.svg?react"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useLazyQuery, useQuery } from "@apollo/client"
import { Link } from "react-router-dom"
import { benchmarkPath, directoryPath } from "~/common/paths"
import { CardFeed } from "~/common/CardFeed"
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "~/ui/card"
import { BarChart } from "~/benchmarks/BarChart"
import { retentionData, costPerEmployeeData } from "~/benchmarks/EmptyState"
import { BENCHMARK_QUERY_DOCUMENT } from "~/benchmarks/benchmarkQuery"
import { MergeLinkButton } from "~/merge/MergeLinkButton"

import { Chart, CommunitySlug, FeatureFlagEnum } from "~/__generated__/graphql"

import { subMonths, formatISO } from "date-fns"
import { FeatureFlag } from "~/common/FeatureFlag"
import { gql } from "~/__generated__"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { useUserDialogContext } from "~/directory/UserDialogContext"
import {
  useCommunityClassname,
  useCommunityFeatures,
} from "~/community/useCommunity"
import { cn } from "~/lib/utils"
import { NewInLibraryModule } from "./NewInLibraryModule"
import { UpcomingEventsModule } from "~/events/UpcomingEventsModule"
import { DiveInModule } from "./DiveInModule"
import { DeepDiveModule } from "./DeepDiveModule"
import { UserName } from "~/directory/UserName"
import { UserOfferModule } from "~/offers/UserOfferModule"
import { CTAChecklist } from "./CTAChecklist"

interface Charts {
  retention: Chart
  costPerEmployee: Chart
}

export const FeedSidebar = () => {
  const { currentUser } = useCurrentUser()

  const today = useRef(new Date())

  const [chartData, setChartData] = useState<Charts | undefined>(
    (!currentUser.hrisIntegration?.initialSyncComplete &&
      !currentUser.manualHrisData && {
        retention: { data: retentionData.data.slice(-3) },
        costPerEmployee: { data: costPerEmployeeData.data.slice(-3) },
      }) ||
      undefined
  )

  const [fetchBenchmarkData] = useLazyQuery(BENCHMARK_QUERY_DOCUMENT, {
    variables: {
      startDate: formatISO(subMonths(today.current, 3), {
        representation: "complete",
      }),
      endDate: formatISO(today.current, { representation: "complete" }),
    },
    onCompleted: (data) => {
      setChartData({
        retention: data.benchmarks.retention,
        costPerEmployee: data.benchmarks.costPerEmployee,
      })
    },
  })

  const [blurCharts, setBlurCharts] = useState(
    (!currentUser.hrisIntegration && !currentUser.manualHrisData) ||
      (currentUser.hrisIntegration &&
        !currentUser.hrisIntegration.initialSyncComplete)
  )

  const features = useCommunityFeatures()

  useEffect(() => {
    if (
      currentUser.manualHrisData ||
      (currentUser.hrisIntegration &&
        currentUser.hrisIntegration.initialSyncComplete)
    ) {
      fetchBenchmarkData()
      setBlurCharts(false)
    }
  }, [
    currentUser.hrisIntegration,
    fetchBenchmarkData,
    currentUser.manualHrisData,
  ])

  const height = 200

  const hrisDataAdded =
    currentUser.hrisIntegration || currentUser.manualHrisData

  return (
    <CardFeed>
      <UserOfferModule />

      {FeatureFlag.get(FeatureFlagEnum.NewOnboarding) && <CTAChecklist />}

      {features.Benchmark && (
        <Card>
          <Link to={benchmarkPath.pattern}>
            <CardHeader>
              <CardTitle className="flex items-center justify-between tracking-wide">
                <span>Benchmarking</span>
                <ArrowRight className="text-blueCharcoal" />
              </CardTitle>
            </CardHeader>
          </Link>

          <CardContent className="border-b border-mercury">
            <div className="font-medium leading-none tracking-wide py-4">
              <span>Retention Rate</span>
            </div>
            {chartData ? (
              <div className={`pr-8 ${blurCharts && "blur-sm"}`}>
                <BarChart
                  chart={chartData.retention}
                  interactive={false}
                  yAxisLine={false}
                  legend={false}
                  height={height}
                  axisValueFormatter={(value: string) => `${value}%`}
                />
              </div>
            ) : (
              <div style={{ height: height }} />
            )}
          </CardContent>
          <CardContent>
            <div className="font-medium leading-none tracking-wide py-4">
              <span>Cost Per Employee</span>
            </div>
            {chartData ? (
              <div className={`pr-8 ${blurCharts && "blur-sm"}`}>
                <BarChart
                  chart={chartData.costPerEmployee}
                  interactive={false}
                  yAxisLine={false}
                  legend={false}
                  height={height}
                  axisValueFormatter={(value: string) => `$${value}k`}
                />
              </div>
            ) : (
              <div style={{ height: height }} />
            )}
          </CardContent>
          <div className={clsx({ hidden: hrisDataAdded })}>
            <MergeLinkButton
              onExitCallback={(success) => {
                const urlParams = new URLSearchParams(window.location.search)
                const urlSuccessParam =
                  urlParams.has("connect") &&
                  urlParams.get("connect") === "true"
                urlSuccessParam === true &&
                  toast.success("Successfully added HRIS account")
              }}
            >
              <CardFooter className="bg-white text-primary hover:bg-primary hover:text-white border-[0px] border-t flex gap-2 items-center justify-center tracking-wide cursor-pointer">
                <Cup />
                <div>Compare my data</div>
              </CardFooter>
            </MergeLinkButton>
          </div>
          <Link
            className={clsx({ hidden: !hrisDataAdded })}
            to={benchmarkPath.pattern}
          >
            <CardFooter className="bg-white text-primary hover:bg-primary hover:text-white border-[0px] border-t flex gap-2 items-center justify-center tracking-wide">
              <Cup />
              <div>Compare my data</div>
            </CardFooter>
          </Link>
        </Card>
      )}

      <ConnectWithSidebarModule />

      {features.FeedDiveIn && <DiveInModule />}

      {features.DeepDive && <DeepDiveModule />}
      {features.NewInLibrary && <NewInLibraryModule />}

      {features.FeedUpcomingEvents && <UpcomingEventsModule />}
    </CardFeed>
  )
}

const ConnectWithSidebarModule = () => {
  const { data } = useQuery(CONNECT_WITH_QUERY_DOCUMENT)
  const { openUserDialog } = useUserDialogContext()

  const ccls = useCommunityClassname()
  const features = useCommunityFeatures()
  const users = data?.connectWith || []
  if (users.length === 0) return

  return (
    <Card>
      <Link to={directoryPath.pattern}>
        <CardHeader>
          <CardTitle className="flex items-center justify-between tracking-wide">
            <span>Connect With</span>
            <ArrowRight
              className={ccls({
                [CommunitySlug.Boardroom]: "text-primary",
                default: "text-blueCharcoal",
              })}
            />
          </CardTitle>
        </CardHeader>
      </Link>

      <CardContent
        className={cn("border-mercury flex flex-col gap-4 pt-4", {
          "border-b": features.Benchmark,
        })}
      >
        {users.map((user) => (
          <div
            key={user.id}
            className="flex gap-4 tracking-wide cursor-pointer items-center"
            onClick={() => openUserDialog(user.id)}
          >
            <AvatarWithFallback size="cta" user={user} />
            <div>
              <div className="font-semibold text-sm">
                <UserName user={user} />
              </div>
              <div className="text-2xs line-clamp-1">
                {titleAndCompany(user.jobTitle, user.companyName)}
              </div>
              <div className="text-3xs text-placeholder">
                {user.place?.full}
              </div>
            </div>
          </div>
        ))}
        <Link
          to={directoryPath.pattern}
          className={ccls({
            [CommunitySlug.Boardroom]: "text-primary",
            default: "",
          })}
        >
          See More
        </Link>
      </CardContent>
    </Card>
  )
}

export const CONNECT_WITH_QUERY_DOCUMENT = gql(`
  query ConnectWith {
    connectWith {
      id
      name
      jobTitle
      companyName
      ...User_Avatar
      place {
        ...Place_UserLocation
      }
    }
  }
`)

const titleAndCompany = (
  jobTitle?: string | null,
  companyName?: string | null
) => {
  return [jobTitle, companyName]
    .filter((text) => text && text.length > 0)
    .join(" - ")
}
