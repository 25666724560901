import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "~/lib/utils"

const badgeVariants = cva(
  "inline-flex items-center rounded-lg border border-slate-200 px-3 py-1 text-2xs font-bold transition-colors focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2  whitespace-nowrap overflow-hidden text-ellipsis",
  {
    variants: {
      variant: {
        default: "border-transparent bg-highlight text-slate-50",
        secondary: "border-transparent bg-secondary text-slate-50",
        secondaryOutline: "border-secondary bg-transparent text-secondary",
        destructive:
          "border-transparent bg-red-500 text-slate-50 hover:bg-red-500/80",
        outline: "text-slate-950",
        primary: "border-transparent bg-primary text-slate-50",
        highlight: "border-transparent bg-highlight text-slate-50",
        highlightOutline: "border-highlight bg-transparent text-highlight",
        success: "border-transparent bg-green-500 text-slate-50",
        info: "border-transparent bg-blue-500 text-slate-50",
        warning: "border-transparent bg-yellow text-slate-50",
        danger: "border-transparent bg-red-500 text-slate-50",
        counter:
          "bg-highlight text-white text-3xs tracking-wide rounded-full p-1 h-4 min-w-4 flex items-center justify-center border-transparent",
        channel:
          "border border-highlight/20 text-2xs tracking-wide px-2 py-[3px] rounded-full self-start flex items-center text-highlight gap-1 font-normal",
        tagSelector: [
          "bg-white text-black px-2 py-1 rounded-sm font-normal text-2xs relative overflow-hidden after:animate-shimmer",
          "after:content-[''] after:absolute after:top-0 after:left-0 after:-translate-x-[100%]",
          "after:w-1/2 after:h-full after:bg-gradient-to-r after:from-transparent after:via-white/90 after:to-transparent",
          "after:-skew-x-12",
        ].join(" "),
      },
      size: {
        default: "",
        sm: "px-2 py-1",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <span className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
