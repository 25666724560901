import { CommunityLogo } from "~/community/CommunityLogo"
import { Outlet } from "react-router-dom"
import React, { createContext, useContext } from "react"
import invariant from "tiny-invariant"

const ApplicationContext = createContext<
  | {
      setAction: (action?: React.ReactNode) => void
    }
  | undefined
>(undefined)

export const useApplicationLayout = () => {
  const context = useContext(ApplicationContext)
  invariant(
    context,
    "useApplicationLayout must be used within a ApplicationLayout"
  )
  return context
}

export const ApplicationLayout = () => {
  const [action, setAction] = React.useState<
    React.ReactNode | null | undefined
  >(null)

  return (
    <ApplicationContext.Provider
      value={{
        setAction,
      }}
    >
      <div className="min-h-screen text-foreground tracking-[0.5px] flex flex-col items-center">
        <div className="grid grid-cols-12 px-8 py-4 max-md:pt-9 mb-16 md:h-[73px] border-b items-center w-full">
          <div className="col-start-2 col-span-10 flex items-center justify-center">
            <CommunityLogo className="max-h-[41px]" />
          </div>
          <div className="col-start-12 col-span-1 flex items-center justify-end">
            {action}
          </div>
        </div>

        <div className="container">
          <Outlet />
        </div>
      </div>
    </ApplicationContext.Provider>
  )
}
