import { useQuery } from "@apollo/client"
import { ChevronDown, ChevronRight, Copy } from "lucide-react"
import { useMemo, useState } from "react"
import toast from "react-hot-toast"
import { useCopyToClipboard } from "usehooks-ts"
import { gql } from "~/__generated__"
import { SailthruSync_AdminFragment } from "~/__generated__/graphql"
import { formatDate } from "~/common/formatDate"
import { UserName } from "~/directory/UserName"
import { Button } from "~/ui/button"
import { Card, CardContent } from "~/ui/card"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "~/ui/collapsible"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"

export const SailthruSyncs = () => {
  const { data, loading } = useQuery(SAILTHRU_SYNC_QUERY_DOCUMENT)
  const sailthruSyncs = data?.sailthruSyncs

  return (
    <>
      {loading ? (
        <LoadingIndicatorCentered />
      ) : (
        <div className="flex flex-col gap-4 mb-8">
          {sailthruSyncs?.map((sailthruSync) => (
            <SailthruSyncCard
              key={sailthruSync.id}
              sailthruSync={sailthruSync}
            />
          ))}
        </div>
      )}
    </>
  )
}

type SailthruSyncCardProps = {
  sailthruSync: SailthruSync_AdminFragment
}

const SailthruSyncCard = ({ sailthruSync }: SailthruSyncCardProps) => {
  const [open, setOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, copy] = useCopyToClipboard()

  const properties = useMemo(
    () => JSON.stringify(sailthruSync.data, null, 2),
    [sailthruSync.data]
  )

  const copyToClipboard = () => {
    copy(properties)
    toast.success("Copied to clipboard")
  }

  return (
    <Card>
      <Collapsible open={open} onOpenChange={setOpen}>
        <CardContent className="pt-4">
          <div className="flex gap-2 items-center">
            <div className="shrink-0">
              <CollapsibleTrigger asChild>
                <Button
                  variant="subtle"
                  size="icon"
                  onClick={() => setOpen(!open)}
                >
                  <>
                    {open ? (
                      <ChevronDown className="w-5 h-5" />
                    ) : (
                      <ChevronRight className="w-5 h-5" />
                    )}
                  </>
                  <span className="sr-only">Toggle</span>
                </Button>
              </CollapsibleTrigger>
            </div>
            <div className="shrink-0 text-2xs pr-2">
              {formatDate(sailthruSync.time, "M/d/yyyy HH:mm:ss")}
            </div>
            <div className="flex-1 font-bold">User Synced to Sailthru</div>
            <div className="flex-1 text-2xs">
              {sailthruSync.user && <UserName user={sailthruSync.user} />}
            </div>
            <div className="flex-1 text-2xs">{sailthruSync.user?.email}</div>
          </div>
          <CollapsibleContent>
            <div className="relative">
              <pre className="m-4 p-4 border border-mercury">{properties}</pre>
              <div className="absolute top-4 right-8">
                <Button
                  variant="subtle"
                  size="icon"
                  onClick={copyToClipboard}
                  className="opacity-50 hover:opacity-100"
                >
                  <Copy className="w-5 h-5" />
                </Button>
              </div>
            </div>
          </CollapsibleContent>
        </CardContent>
      </Collapsible>
    </Card>
  )
}

gql(`
  fragment SailthruSync_Admin on SailthruSync {
    id
    sailthruIdentifier
    user {
      ...User_Avatar
      email
    }
    time
    data
  }
`)

const SAILTHRU_SYNC_QUERY_DOCUMENT = gql(`
  query SailthruSyncs {
    sailthruSyncs {
      ...SailthruSync_Admin
    }
  }
`)
