import { useQuery } from "@apollo/client"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import invariant from "tiny-invariant"
import { SCREEN_VARIANTS_QUERY_DOCUMENT } from "./admin/AdminScreenVariantsScreen"
import i18n from "~/common/i18n"
import {
  ScreenEnum,
  ScreenVariant_AdminFragment,
  ScreenVariantsQuery,
} from "~/__generated__/graphql"
import { camelCase } from "change-case"

const ScreenVariantsContext = createContext<{
  data: ScreenVariantsQuery | undefined
  loaded: boolean
}>({
  data: undefined,
  loaded: false,
})

export const useScreenVariants = () => {
  const context = useContext(ScreenVariantsContext)
  invariant(
    context,
    "useScreenVariants must be used within a ScreenVariantsProvider"
  )
  return context
}

export const useScreenVariant = (
  screen: ScreenEnum,
  variant?: string | null
) => {
  const { data } = useScreenVariants()
  const [screenVariant, setScreenVariant] =
    useState<ScreenVariant_AdminFragment | null>(null)

  useEffect(() => {
    const screenVariant = data?.screenVariants.nodes.find(
      (node) =>
        node.screen === screen &&
        ((!variant && !node.variantIdentifier) ||
          variant === node.variantIdentifier)
    )
    if (screenVariant?.overrides && screenVariant.overrides.translations) {
      i18n.addResourceBundle(
        "en-US",
        camelCase(screenVariant.screen),
        screenVariant.overrides.translations,
        true,
        true
      )
    }
    setScreenVariant(screenVariant || null)
  }, [data, screen, variant])

  return screenVariant
}

export const ScreenVariantsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { data, loading } = useQuery(SCREEN_VARIANTS_QUERY_DOCUMENT)
  const hasLoadedRef = useRef(false)

  useEffect(() => {
    data?.screenVariants.nodes.forEach((screenVariant) => {
      if (
        screenVariant.overrides &&
        screenVariant.overrides.translations &&
        !screenVariant.variantIdentifier
      ) {
        i18n.addResourceBundle(
          "en-US",
          camelCase(screenVariant.screen),
          screenVariant.overrides.translations,
          true,
          true
        )
      }
    })
    if (data?.screenVariants) {
      hasLoadedRef.current = true
    }
  }, [data])

  if (loading) {
    return null
  }

  return (
    <ScreenVariantsContext.Provider
      value={{ data, loaded: hasLoadedRef.current }}
    >
      {children}
    </ScreenVariantsContext.Provider>
  )
}
