import { Transition, TransitionChild } from "@headlessui/react"
import copy from "copy-to-clipboard"
import { useCallback, useEffect, useState } from "react"
import { cn } from "~/lib/utils"
import { Button } from "~/ui/button"
import { SimpleTooltip } from "./SimpleTooltip"
import { Check, ClipboardCopy } from "lucide-react"
import toast from "react-hot-toast"

export const CopyToClipboard = ({
  variant = "default",
  text,
  children,
  onCopy,
  className,
}: {
  variant?: "default" | "adornment"
  text: string
  children?: React.ReactNode
  onCopy?: () => void
  className?: string
}) => {
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false)
      }, 2000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isCopied])

  const copyToClipboard = useCallback(() => {
    copy(text)
    setIsCopied(true)
    if (variant === "adornment") {
      toast.success("Copied to clipboard")
    }
    onCopy?.()
  }, [text, onCopy, variant])

  return (
    <Button
      variant="subtle"
      size="tight"
      onClick={copyToClipboard}
      className={cn(
        "cursor-pointer bg-transparent min-w-16 justify-start",
        variant === "adornment" &&
          "h-10 w-10 border border-mercury rounded-none",
        className
      )}
    >
      <SimpleTooltip content="Copy to clipboard">
        <>
          <div className="relative">
            <Transition show={isCopied}>
              <TransitionChild>
                <div className="absolute left-0 top-0 transition-opacity duration-200 data-[closed]:opacity-0 flex items-center gap-1">
                  <Check className="w-3 h-3" />
                  {variant !== "adornment" && "Copied"}
                </div>
              </TransitionChild>
            </Transition>
            <Transition show={!isCopied}>
              <TransitionChild>
                <div className="absolute left-0 top-0 transition-opacity duration-200 data-[closed]:opacity-0 flex items-center gap-1">
                  <ClipboardCopy className="w-3 h-3" />
                  {variant !== "adornment" && children}
                </div>
              </TransitionChild>
            </Transition>
            <div className="invisible flex items-center gap-1">
              <ClipboardCopy className="w-3 h-3" />
              {variant !== "adornment" && children}
            </div>
          </div>
        </>
      </SimpleTooltip>
    </Button>
  )
}
