import { useCallback, useEffect, useMemo, useState } from "react"
import { gql } from "~/__generated__"
import { useWizard } from "~/ui/Wizard"
import { PricingTableTier } from "./PricingTableStep"
import { TierIntervalEnum } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { LoadingStep } from "./LoadingStep"
import { useTiers } from "~/tiers/TiersProvider"
import { DialogFooter, DialogHeader, DialogTitle } from "~/ui/dialog"
import { Button } from "~/ui/button"
import { TextField } from "~/components/forms/TextField"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form } from "~/ui/form"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { useLocalStorage } from "usehooks-ts"
import { P } from "~/ui/typography"
import { formatCurrency } from "~/common/formatCurrency"
import { CheckboxField } from "~/components/forms/CheckboxField"
import { Translation } from "~/common/Translation"
import { Link } from "~/ui/Link"
import { useCommunity } from "~/community/useCommunity"
import { subscriberAgreementPath } from "~/common/paths"
import { formatDate } from "~/common/formatDate"
import { cn } from "~/lib/utils"

const formSchema = z.object({
  firstName: z.string().min(2, { message: "First name is required" }),
  lastName: z.string().min(2, { message: "Last name is required" }),
  consent: z.boolean().refine((value) => value === true, {
    message: "You must agree to the terms and conditions to subscribe",
  }),
})

export type FormValues = z.infer<typeof formSchema>

export const CheckoutStep = () => {
  const { meta } = useWizard()
  const { selectedTier, selectedInterval, paymentIntent, upcomingInvoice } =
    useMemo(() => {
      const selectedTier = meta.selectedTier as PricingTableTier
      const selectedInterval = meta.selectedInterval as TierIntervalEnum
      const paymentIntent = meta.paymentIntent as
        | {
            id: string
            clientSecret: string
            livemode: boolean
            status: string
            amount: number
          }
        | null
        | undefined
      const upcomingInvoice = meta.upcomingInvoice as
        | { amountRemaining: number; nextPaymentAttempt?: string }
        | null
        | undefined
      return {
        selectedTier,
        selectedInterval,
        paymentIntent,
        upcomingInvoice,
      }
    }, [
      meta.selectedTier,
      meta.selectedInterval,
      meta.paymentIntent,
      meta.upcomingInvoice,
    ])
  const [clientSecret, setClientSecret] = useState<string | null>(
    paymentIntent?.clientSecret || null
  )
  const { formatTierName } = useTiers()

  const stripe = useStripe()

  useEffect(() => {
    if (paymentIntent) {
      setClientSecret(paymentIntent.clientSecret)
    }
  }, [paymentIntent])

  if (!selectedTier) return <LoadingStep />

  return (
    <>
      <DialogHeader>
        <DialogTitle>
          Subscribe to {formatTierName(selectedTier, selectedInterval)}
        </DialogTitle>
      </DialogHeader>
      {paymentIntent && (
        <P>
          Please enter your payment information to subscribe to{" "}
          {formatTierName(selectedTier, selectedInterval)}. You will pay{" "}
          {formatCurrency(paymentIntent?.amount || 0)} to start and your
          subscription will renew for{" "}
          {formatCurrency(upcomingInvoice?.amountRemaining || 0)} on{" "}
          {formatDate(upcomingInvoice?.nextPaymentAttempt!, "MMMM d, yyyy")}.
        </P>
      )}
      {clientSecret && (
        <Elements stripe={stripe} options={{ clientSecret }}>
          <CheckoutForm paymentIntent={paymentIntent || undefined} />
        </Elements>
      )}
      {!clientSecret && <CheckoutForm />}
    </>
  )
}
CheckoutStep.displayName = "CheckoutStep"

const CheckoutForm = ({
  paymentIntent,
}: {
  paymentIntent?: {
    id: string
    clientSecret: string
    livemode: boolean
    status: string
    amount: number
  }
}) => {
  const { termsOfUseUrl, privacyPolicyUrl } = useCommunity()
  const stripe = useStripe()
  const elements = useElements()
  const { meta, goToStep, back } = useWizard()
  const { currentUser } = useCurrentUser()

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: currentUser.firstName || "",
      lastName: currentUser.lastName || "",
    },
  })
  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)
  const [runUserRefreshStripeSubscriptions] = useSafeMutation(
    USER_REFRESH_STRIPE_SUBSCRIPTIONS
  )

  const [, setReturnPath] = useLocalStorage("returnPath", "")
  const [, setReturnMeta] = useLocalStorage("returnMeta", {})

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      if (!stripe || !elements) return
      setIsLoading(true)

      if (!currentUser.firstName || !currentUser.lastName) {
        await runUserUpdate({
          variables: {
            input: {
              firstName: formValues.firstName || currentUser.firstName,
              lastName: formValues.lastName || currentUser.lastName,
            },
          },
        })
      }

      setReturnMeta({
        ...meta,
        initialManageSubscriptionWizardStep: "SocialContractStep",
      })
      setReturnPath(window.location.pathname)

      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href + "?redirect=1",
        },
        redirect: "if_required",
      })

      // This is really only necessary in environments where we don't receive Stripe webhooks
      await runUserRefreshStripeSubscriptions()

      if (result.error) {
        console.error(result.error)
      }

      if (result.paymentIntent) {
        console.log("redirecting to social contract")
        setReturnMeta({}) // Clear return meta
        setReturnPath("") // Clear return path
        setTimeout(() => {
          goToStep("SocialContractStep", "forward", false)
        }, 500)
        return
      }

      setIsLoading(false)
    },
    [
      stripe,
      elements,
      currentUser,
      runUserUpdate,
      goToStep,
      runUserRefreshStripeSubscriptions,
      meta,
      setReturnPath,
      setReturnMeta,
    ]
  )

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className="flex flex-col gap-4">
          <div className={cn("grid grid-cols-2 gap-4")}>
            <TextField
              control={form.control}
              name="firstName"
              label="First Name"
              disabled={isLoading || !!currentUser.firstName}
            />
            <TextField
              control={form.control}
              name="lastName"
              label="Last Name"
              disabled={isLoading || !!currentUser.lastName}
            />
          </div>

          {paymentIntent && <PaymentElement />}

          <CheckboxField
            control={form.control}
            name="consent"
            label={
              <Translation
                ns="signup"
                i18nKey="consent"
                components={{
                  TermsOfService: (
                    <Link
                      href={termsOfUseUrl || undefined}
                      target="_blank"
                      rel="noreferrer nofollow"
                    />
                  ),
                  PrivacyPolicy: (
                    <Link
                      href={privacyPolicyUrl || undefined}
                      target="_blank"
                      rel="noreferrer nofollow"
                    />
                  ),
                  SubscriberAgreement: (
                    <Link to={subscriberAgreementPath.pattern} />
                  ),
                }}
              />
            }
            labelClassName="font-normal text-2xs text-left"
            required
            disabled={isLoading}
          />

          <DialogFooter>
            <Button
              type="button"
              variant="link"
              size="inline"
              onClick={back}
              disabled={isLoading}
            >
              Back
            </Button>
            <Button type="submit" disabled={isLoading}>
              Subscribe
            </Button>
          </DialogFooter>
        </div>
      </form>
    </Form>
  )
}

const USER_REFRESH_STRIPE_SUBSCRIPTIONS = gql(`
  mutation UserRefreshStripeSubscriptions {
    userRefreshStripeSubscriptions(input: {}) {
      user {
        ...User_CurrentUser
      }
    }
  }
`)
