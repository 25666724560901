import { useEffect } from "react"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"

export const ApplicationMostExcitedAboutScreen = () => {
  const { openSubscriptionWizard } = useSubscription()

  useEffect(() => {
    openSubscriptionWizard(
      "IntentStep",
      { disableSelectCurrentTier: false },
      (handle) => {
        handle.setDisableClose(true)
      }
    )
  }, [openSubscriptionWizard])

  return <div></div>
}
