import { Outlet, useNavigate } from "react-router-dom"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"
import { useEffect } from "react"
import { aboutYouPath, applicationFitWaitingScreenPath } from "~/common/paths"
import { FeatureFlagEnum } from "~/__generated__/graphql"
import { FeatureFlag } from "~/common/FeatureFlag"

export const RequireUserOnboarded = () => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUserMaybe()
  const onboardedRoutePattern = FeatureFlag.get(FeatureFlagEnum.NewOnboarding)
    ? applicationFitWaitingScreenPath.pattern
    : aboutYouPath.pattern

  useEffect(() => {
    if (currentUser && !currentUser.onboarded) {
      navigate(onboardedRoutePattern)
    }
  }, [currentUser, navigate, onboardedRoutePattern])

  if (currentUser && !currentUser.onboarded) {
    return null
  }

  return <Outlet />
}
