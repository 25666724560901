import { Offer, TierIntervalEnum } from "~/__generated__/graphql"
import { useWizard } from "~/ui/Wizard"
import { useSubscription } from "../SubscriptionProvider"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { LoadingStep } from "./LoadingStep"
import { PricingTableTier } from "./PricingTableStep"
import toast from "react-hot-toast"
import { useUserOffers } from "~/offers/UserOffersProvider"

export const SubscribeToTierStep = () => {
  const { goToStep, meta, addToMeta } = useWizard()
  const { subscribeToTier } = useSubscription()
  const { getBestUserOffer } = useUserOffers()

  const { offer, selectedTier, selectedInterval } = useMemo(() => {
    const selectedTier = meta.selectedTier as PricingTableTier
    const selectedInterval = meta.selectedInterval as TierIntervalEnum
    const offer = meta.offer
      ? (meta.offer as Offer)
      : getBestUserOffer(selectedTier.level, selectedInterval)?.offer

    return {
      offer,
      selectedTier,
      selectedInterval,
    }
  }, [meta.selectedTier, meta.selectedInterval, meta.offer, getBestUserOffer])

  const initialized = useRef(false)

  const changeTier = useCallback(async () => {
    if (!(selectedTier && selectedInterval)) {
      toast.error("An unexpected error has occurred. Please try again later.")
      return
    }

    const { paymentIntent, upcomingInvoice, errors } = await subscribeToTier(
      selectedTier.level,
      selectedInterval,
      offer?.id
    )
    if (errors) return

    if (paymentIntent?.status !== "succeeded") {
      addToMeta("paymentIntent", paymentIntent)
      addToMeta("upcomingInvoice", upcomingInvoice)
      goToStep("CheckoutStep")
      return
    } else {
      // Does this occur when the user subscribes to free tier?
      console.log({ paymentIntent })
      console.log("no idea what to do here")
      return
    }
  }, [
    selectedTier,
    selectedInterval,
    goToStep,
    addToMeta,
    offer,
    subscribeToTier,
  ])

  const isReady = useMemo(() => {
    return selectedTier && selectedInterval
  }, [selectedTier, selectedInterval])

  useEffect(() => {
    if (isReady && !initialized.current) {
      initialized.current = true
      changeTier()
    }
  }, [changeTier, isReady])

  return <LoadingStep />
}

SubscribeToTierStep.displayName = "SubscribeToTierStep"
