import { QueryResult } from "@apollo/client"
import { gql } from "~/__generated__"
import { RepliesQueryQuery } from "~/__generated__/graphql"
import { PostFeed } from "~/feed/PostFeed"
import { Error } from "~/ui/Error"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"

export const RepliesFeed = ({
  repliesResult,
}: {
  repliesResult: QueryResult<RepliesQueryQuery>
}) => {
  const { loading, data, previousData, error, fetchMore } = repliesResult

  const replies =
    data?.posts.edges.map((e) => e.node) ||
    previousData?.posts.edges.map((e) => e.node) ||
    []

  if (error) return <Error message="Error loading replies." />

  return (
    <div className="pb-16">
      {loading && (
        <div className="my-6">
          <LoadingIndicatorCentered />
        </div>
      )}

      <PostFeed posts={replies} isReplies trackImpressions />

      <InfiniteLoadMore
        onEndReached={() =>
          fetchMore({
            variables: { repliesCursor: data?.posts.pageInfo.endCursor },
          })
        }
        canLoadMore={!loading && !!data?.posts.pageInfo.hasNextPage}
        loadingText="Loading replies..."
        loading={loading && replies.length > 0}
      />
    </div>
  )
}

export const REPLIES_QUERY_DOCUMENT = gql(`
  query RepliesQuery($parentPostId: ID, $articleId: ID, $parentReplyId: ID, $repliesCursor: String) {
    posts(parentPostId: $parentPostId, articleId: $articleId, parentReplyId: $parentReplyId, first: 20, after: $repliesCursor) {
      edges {
        node {
          ...Post_Card
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`)
