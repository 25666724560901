import { Sailboat } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"
import { SailthruSyncs } from "~/admin/SailthruSyncs"

export const AdminSailthruSyncsScreen = () => {
  return (
    <>
      <AdminHeader title="Latest Syncs to Sailthru" Icon={Sailboat} />
      <SailthruSyncs />
    </>
  )
}
