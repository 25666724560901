import { Translation } from "~/common/Translation"
import { Card, CardContent } from "~/ui/card"
import { H3, P } from "~/ui/typography"
import { useApplicationLayout } from "../layouts/ApplicationLayout"
import { useEffect, useState } from "react"
import { Button } from "~/ui/button"
import { ScreenEnum } from "~/__generated__/graphql"
import { useScreenVariant } from "~/screens/ScreenVariantsProvider"
import { useSearchParams } from "react-router-dom"
import { useFetchLead } from "../queries/useFetchLead"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { FeatureFlag } from "~/common/FeatureFlag"
import { Form } from "~/ui/form"
import {
  FeatureFlagEnum,
  UserResponsibilitiesInput,
  UserResponsibilityQuestionsEnum,
  UserSignupInput,
  CommunitySlug,
} from "~/__generated__/graphql"
import { gql } from "~/__generated__"
import { useForm } from "react-hook-form"
import { useCommunity } from "~/community/useCommunity"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { useSafeMutation } from "~/common/useSafeMutation"
import { TextField } from "~/components/forms/TextField"
import { ResponsibilityQuestions } from "./ApplicationLandingScreen/ResponsibilityQuestions"
import { MadlibsQuestions } from "./ApplicationLandingScreen/MadlibsQuestions"
import { displayErrors } from "~/common/validations"
import { useTranslation } from "react-i18next"
import { camelCase } from "change-case"
import { useNavigate } from "react-router-dom"
import { applicationFitWaitingScreenPath, loadingPath } from "~/common/paths"
import { getMetaVar } from "~/common/getMetaVar"

const responsibilityQuestionsSchema = z.object({
  // Safespace
  definingGuidingAndImplementingThePeopleStrategy: z.boolean(),
  actingAsAKeyDecisionMaker: z.boolean(),
  recruitingAmazingTalent: z.boolean(),
  onboardingCompensationPerformanceManagement: z.boolean(),
  managingHrBudgetAndAllocatingResources: z.boolean(),
  designingAndImplementingTrainingPrograms: z.boolean(),
  allOperationsWithinMyCompany: z.boolean(),
  noneOfTheAbove: z.boolean(),

  // BoardRoom
  leadingOrAdvisingOnLongTermStrategy: z.boolean(),
  leadingOrContributingToCorporateDevelopmentEfforts: z.boolean(),
  managingMyPatientsAndTheirHealthJourney: z.boolean(),
  overseeingDailyOperationsThatHelpMyOrgGrowAndOperate: z.boolean(),
  ensuringOrganizationalComplianceWithHealthcareLaws: z.boolean(),
  contributingToHealthcareInvestmentResearchAndOrAnalysis: z.boolean(),
  workingWithInfluentialStakeholdersToDriveHealthSystem: z.boolean(),
  facilitatingDevelopmentAndOrBrokerageOfRealEstate: z.boolean(),
  expandingBusinessDevelopmentEffortsSalesMarketing: z.boolean(),

  // Marketingland
  managementOfAMarketingTeam: z.boolean(),
  developmentAndExecutionOfStrategicMarketingPlans: z.boolean(),
  marketResearchAndConsumerInsights: z.boolean(),
  contentAndBrandStrategy: z.boolean(),
  drivingRevenueThroughLeadGenerationEfforts: z.boolean(),
  salesBusinessDevelopmentTeam: z.boolean(),
  aStrategyAndBudgetForAtLeastOneMarketingChannel: z.boolean(),
  assessmentAndSelectionOfOutsideVendorsToolsAndAgencies: z.boolean(),
})

const formSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  yearsOfExperience: z.string(),
  jobFunction: z.string(),
  jobTitle: z.string(),
  teamSize: z.string(),
  manageContribute: z.string(),
  organizationType: z.string(),
  business: z.string(),
  cupsOfCoffee: z.string(),
  responsibilityQuestions: responsibilityQuestionsSchema,
})

const REQUIRED_FIELDS_FOR_COMMUNITY: Record<CommunitySlug, string[]> = {
  [CommunitySlug.Safespace]: [
    "yearsOfExperience",
    "jobFunction",
    "jobTitle",
    "teamSize",
    "manageContribute",
  ],
  [CommunitySlug.Boardroom]: [
    "yearsOfExperience",
    "jobFunction",
    "jobTitle",
    "organizationType",
  ],
  [CommunitySlug.Marketingland]: [
    "yearsOfExperience",
    "business",
    "jobFunction",
    "jobTitle",
    "organizationType",
    "teamSize",
    "cupsOfCoffee",
  ],
}

const getFormSchemaWithRequiredFields = (communitySlug: CommunitySlug) => {
  return formSchema.superRefine((data, ctx) => {
    if (!data.email) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Email is required",
        path: ["email"],
      })
    }

    REQUIRED_FIELDS_FOR_COMMUNITY[communitySlug].forEach((field) => {
      if (!data[field as keyof typeof data]) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `${field} is required for Safespace`,
          path: [field],
        })
      }
    })
  })
}

export type FormValues = z.infer<typeof formSchema>
type ResponsibilityQuestionValues = z.infer<
  typeof responsibilityQuestionsSchema
>

export const ApplicationLandingScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  useScreenVariant(ScreenEnum.ApplicationLandingScreen, searchParams.get("v"))
  const community = useCommunity()

  const { setAction } = useApplicationLayout()
  const { userResponsibilityQuestions } = useCommunity()
  const [leadId, setLeadId] = useState<string | null>(searchParams.get("lid"))
  const qaToolsEnabled = getMetaVar("qa-tools-enabled") === "true"

  useEffect(() => {
    if (searchParams.has("lid")) {
      setLeadId(searchParams.get("lid"))
      searchParams.delete("lid")
      setSearchParams(searchParams)
    }
  }, [searchParams, setLeadId, setSearchParams])

  const { lead, loading: leadLoading } = useFetchLead(leadId)
  const [runUserCreate, { loading }] = useSafeMutation(USER_SIGNUP_MUTATION)
  const navigate = useNavigate()

  if (!FeatureFlag.get(FeatureFlagEnum.NewOnboarding)) {
    navigate(loadingPath({}))
  }

  const form = useForm<FormValues>({
    resolver: zodResolver(getFormSchemaWithRequiredFields(community.slug)),
    defaultValues: {
      email: "",
      yearsOfExperience: "",
      jobFunction: "",
      jobTitle: "",
      manageContribute: "",
      organizationType: "",
      teamSize: "",
      business: "",
      cupsOfCoffee: "",
      responsibilityQuestions: {
        // Safespace
        definingGuidingAndImplementingThePeopleStrategy: false,
        actingAsAKeyDecisionMaker: false,
        recruitingAmazingTalent: false,
        onboardingCompensationPerformanceManagement: false,
        managingHrBudgetAndAllocatingResources: false,
        designingAndImplementingTrainingPrograms: false,
        allOperationsWithinMyCompany: false,
        noneOfTheAbove: false,

        // BoardRoom
        leadingOrAdvisingOnLongTermStrategy: false,
        leadingOrContributingToCorporateDevelopmentEfforts: false,
        managingMyPatientsAndTheirHealthJourney: false,
        overseeingDailyOperationsThatHelpMyOrgGrowAndOperate: false,
        ensuringOrganizationalComplianceWithHealthcareLaws: false,
        contributingToHealthcareInvestmentResearchAndOrAnalysis: false,
        workingWithInfluentialStakeholdersToDriveHealthSystem: false,
        facilitatingDevelopmentAndOrBrokerageOfRealEstate: false,
        expandingBusinessDevelopmentEffortsSalesMarketing: false,

        // Marketingland
        managementOfAMarketingTeam: false,
        developmentAndExecutionOfStrategicMarketingPlans: false,
        marketResearchAndConsumerInsights: false,
        contentAndBrandStrategy: false,
        drivingRevenueThroughLeadGenerationEfforts: false,
        salesBusinessDevelopmentTeam: false,
        aStrategyAndBudgetForAtLeastOneMarketingChannel: false,
        assessmentAndSelectionOfOutsideVendorsToolsAndAgencies: false,
      },
    },
  })
  const { t } = useTranslation("responsibilityQuestions")
  const { setValue } = form

  useEffect(() => {
    setAction(<Button className="bg-highlight">Action</Button>)

    return () => {
      setAction(null)
    }
  }, [setAction])

  useEffect(() => {
    if (lead) {
      form.setValue("email", lead.email)
    }
  }, [lead, form])

  const onSubmit = async (formData: FormValues) => {
    const userResponsibilitiesInput: UserResponsibilitiesInput = {}

    Object.values(UserResponsibilityQuestionsEnum).forEach((question) => {
      if (!userResponsibilityQuestions.includes(question)) {
        return
      }

      userResponsibilitiesInput[
        camelCase(question) as keyof UserResponsibilitiesInput
      ] = {
        question: t(question),
        answer:
          formData.responsibilityQuestions[
            camelCase(question) as keyof FormValues["responsibilityQuestions"]
          ],
      }
    })

    const userInput: UserSignupInput = {
      email: formData.email,
      userSignupMadlibsInput: {
        yearsOfExperience: formData.yearsOfExperience,
        jobFunction: formData.jobFunction,
        jobTitle: formData.jobTitle,
        teamSize: formData.teamSize,
        isContributor: formData.manageContribute
          ? ["contribute_to", "manage_and_contribute_to"].includes(
              formData.manageContribute
            )
          : null,
        isManager: formData.manageContribute
          ? ["manage", "manage_and_contribute_to"].includes(
              formData.manageContribute
            )
          : null,
      },
      userResponsibilities: userResponsibilitiesInput,
    }

    const { errors } = await runUserCreate({
      variables: {
        input: {
          ...userInput,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      return false
    } else {
      window.location.href = applicationFitWaitingScreenPath.pattern
    }
  }

  if (leadLoading) {
    return <LoadingIndicatorCentered />
  }

  type KeyValue = {
    key: keyof FormValues
    value: string
  }
  const FIT_QUESTIONS: Record<
    CommunitySlug,
    {
      madlibs: KeyValue[]
      responsibilityQuestions: Partial<ResponsibilityQuestionValues> | []
    }
  > = {
    [CommunitySlug.Safespace]: {
      madlibs: [
        {
          key: "yearsOfExperience",
          value: "10+",
        },
        {
          key: "jobFunction",
          value: "Compensation & Benefits",
        },
        {
          key: "manageContribute",
          value: "manage",
        },
        {
          key: "teamSize",
          value: "1",
        },
        {
          key: "jobTitle",
          value: "Manager",
        },
      ],
      responsibilityQuestions: {
        definingGuidingAndImplementingThePeopleStrategy: true,
      },
    },
    [CommunitySlug.Boardroom]: {
      madlibs: [
        { key: "yearsOfExperience", value: "4-10" },
        { key: "jobFunction", value: "Consulting" },
        { key: "jobTitle", value: "Consultant" },
        { key: "organizationType", value: "Consulting Firm" },
      ],
      responsibilityQuestions: {
        leadingOrAdvisingOnLongTermStrategy: true,
      },
    },
    [CommunitySlug.Marketingland]: {
      madlibs: [
        { key: "yearsOfExperience", value: "6-10" },
        { key: "business", value: "B2C" },
        { key: "organizationType", value: "at a brand" },
        { key: "teamSize", value: "2-5" },
        { key: "cupsOfCoffee", value: "4 cups" },
        { key: "jobFunction", value: "Influencer" },
        { key: "jobTitle", value: "Marketing Manager" },
      ],
      responsibilityQuestions: {
        managementOfAMarketingTeam: true,
      },
    },
  }

  const UNFIT_QUESTIONS: Record<
    CommunitySlug,
    {
      madlibs: KeyValue[]
      responsibilityQuestions: Partial<ResponsibilityQuestionValues> | []
    }
  > = {
    [CommunitySlug.Safespace]: {
      madlibs: [
        {
          key: "yearsOfExperience",
          value: "10+",
        },
        {
          key: "jobFunction",
          value: "Compensation & Benefits",
        },
        {
          key: "manageContribute",
          value: "manage",
        },
        {
          key: "teamSize",
          value: "1",
        },
        {
          key: "jobTitle",
          value: "Manager",
        },
      ],
      responsibilityQuestions: {
        noneOfTheAbove: true,
      },
    },
    [CommunitySlug.Boardroom]: {
      madlibs: [
        { key: "yearsOfExperience", value: "4-10" },
        { key: "jobFunction", value: "Consulting" },
        { key: "jobTitle", value: "Consultant" },
        { key: "organizationType", value: "Consulting Firm" },
      ],
      responsibilityQuestions: {
        facilitatingDevelopmentAndOrBrokerageOfRealEstate: true,
      },
    },
    [CommunitySlug.Marketingland]: {
      madlibs: [
        { key: "yearsOfExperience", value: "0-5" },
        { key: "business", value: "B2C" },
        { key: "organizationType", value: "at an agency" },
        { key: "teamSize", value: "just me!" },
        { key: "cupsOfCoffee", value: "1 cup" },
        { key: "jobFunction", value: "Influencer" },
        { key: "jobTitle", value: "Marketing Manager" },
      ],
      responsibilityQuestions: {
        noneOfTheAbove: true,
      },
    },
  }

  const selectQuestions = (questionType: "fit" | "unfit") => {
    const questions =
      questionType === "fit"
        ? FIT_QUESTIONS[community.slug]
        : UNFIT_QUESTIONS[community.slug]

    questions.madlibs.forEach((question) => {
      setValue(question.key, question.value)
    })

    Object.entries(questions.responsibilityQuestions).forEach(
      ([key, value]) => {
        setValue(
          `responsibilityQuestions.${key}` as `responsibilityQuestions.${keyof ResponsibilityQuestionValues}`,
          value
        )
      }
    )
  }

  return (
    <div className="flex flex-col items-center gap-8">
      {qaToolsEnabled && (
        <div className="fixed bottom-8 right-8">
          <div className="flex flex-col items-end space-y-4">
            <h3 className="text-sm font-medium text-gray-500">QA Tools</h3>
            <div className="flex flex-col space-y-2">
              <button
                onClick={() => selectQuestions("fit")}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 text-sm"
              >
                Select Fit
              </button>
              <button
                onClick={() => selectQuestions("unfit")}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 text-sm"
              >
                Select Unfit
              </button>
            </div>
          </div>
        </div>
      )}
      <H3 className="text-center max-w-128">
        <Translation
          ns={camelCase(ScreenEnum.ApplicationLandingScreen)}
          i18nKey={"title"}
        />
      </H3>
      <P className="text-center">
        <Translation
          ns={camelCase(ScreenEnum.ApplicationLandingScreen)}
          i18nKey={"subtitle"}
        />
      </P>
      <div className="relative mt-8 max-w-[700px] mx-auto">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-6"
          >
            <Card>
              <CardContent className="p-8">
                <TextField
                  containerClassName="flex gap-2 items-center space-y-0"
                  labelClassName="min-w-[140px] lg:min-w-[200px] text-lg font-normal"
                  control={form.control}
                  name="email"
                  label="Email Address"
                />
              </CardContent>
            </Card>
            <Card>
              <CardContent className="p-8 text-lg">
                <MadlibsQuestions control={form.control} />
              </CardContent>
            </Card>

            <Card>
              <CardContent className="p-8">
                <p className="text-lg mb-6">
                  <Translation
                    ns={camelCase(ScreenEnum.ApplicationLandingScreen)}
                    i18nKey="responsibilityQuestionsLabel"
                  />
                </p>

                <ResponsibilityQuestions
                  control={form.control}
                  userResponsibilityQuestions={userResponsibilityQuestions}
                />
                <Button
                  type="submit"
                  className="mt-8 bg-highlight"
                  disabled={loading}
                >
                  <Translation
                    ns={camelCase(ScreenEnum.ApplicationLandingScreen)}
                    i18nKey="submitCopy"
                  />
                </Button>
                <p className="font-sans text-muted-foreground text-3xs w-full mt-8">
                  <Translation
                    ns={camelCase(ScreenEnum.ApplicationLandingScreen)}
                    i18nKey="disclaimer"
                  />
                </p>
              </CardContent>
            </Card>
          </form>
        </Form>
      </div>
    </div>
  )
}

const USER_SIGNUP_MUTATION = gql(`
  mutation LandingUserSignup($input: UserSignupInput!) {
    userSignup(input: $input) {
      user {
        id
      }
      sessionId
    }
  }
`)
