import { useMemo } from "react"
import { cn } from "~/lib/utils"
import { SimpleTooltip } from "~/ui/SimpleTooltip"

export const Battery = ({
  level,
  className,
}: {
  level: number
  className?: string
  charge?: boolean
}) => {
  return (
    <SimpleTooltip content={`${level}%`}>
      <span>
        <BatteryIcon
          level={level}
          className={cn(
            "w-6 h-6",
            level > 0 && level < 30 && "text-red-500",
            level >= 30 && "text-success",
            className
          )}
        />
      </span>
    </SimpleTooltip>
  )
}

const BatteryIcon = ({
  level,
  className,
}: {
  level: number
  className?: string
}) => {
  const width = useMemo(() => {
    // The 100% width is 19.9. We want to scale the width of the battery based on the level.
    return 19.9 * (level / 100)
  }, [level])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 13"
      className={className}
    >
      <path
        d="M23.8 3c-.1-.6-.4-1.1-.8-1.5-.4-.4-.9-.7-1.5-.8-.6-.1-1.3-.2-2.2-.2H5.1C4.3.5 3.6.5 3 .7c-.6.1-1.1.4-1.5.8-.4.4-.7.9-.8 1.5-.1.6-.2 1.3-.2 2.1v2.5c0 .9 0 1.6.2 2.2.1.6.4 1.1.8 1.5.4.4.9.7 1.5.8.6.1 1.3.2 2.2.2h14.2c.9 0 1.6 0 2.2-.2.6-.1 1.1-.4 1.5-.8.4-.4.7-.9.8-1.5.1-.6.2-1.3.2-2.2V5.2c0-.9 0-1.6-.2-2.2Zm-.7 4.8c0 .5 0 1.1-.2 1.6-.1.5-.3.8-.6 1.1-.3.3-.7.5-1.1.6-.5.1-1 .2-1.6.2H4.9c-.6 0-1.1 0-1.6-.2-.5-.1-.8-.3-1.1-.6-.3-.3-.5-.7-.6-1.1-.1-.5-.2-1-.2-1.6V5c0-.6 0-1.1.2-1.6.1-.5.3-.9.6-1.1.3-.3.7-.5 1.1-.6.5-.1 1-.2 1.6-.2h14.6c.5 0 1.1 0 1.6.2.5.1.8.3 1.1.6.3.3.5.7.6 1.1.1.5.2 1 .2 1.6v2.9Zm1.8.7V4.2c.2 0 .4.1.7.3.2.2.4.4.6.8.2.3.3.7.3 1.1s0 .8-.3 1.1c-.1.3-.4.5-.6.8-.2.2-.5.3-.7.3Z"
        fill="currentColor"
      />
      {width > 0 && (
        <rect
          x="2.3"
          y="2.3"
          width={width}
          height="8"
          rx="1.7"
          ry="1.7"
          fill="currentColor"
        />
      )}
      {level === 0 && (
        <g transform="translate(7 -1) scale(0.7)">
          <path
            d="M1.273 11.7 9.88 1.28c.139-.167.407-.024.346.184L8.002 9.028h5.073a.2.2 0 0 1 .154.328L4.286 20.088c-.145.174-.422.01-.34-.2l3.056-7.86H1.427a.2.2 0 0 1-.154-.327Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.001 12.028H1.426a.2.2 0 0 1-.154-.327L9.88 1.28c.139-.168.408-.025.346.183L8.001 9.028h5.074a.2.2 0 0 1 .153.328L4.285 20.088c-.145.174-.422.01-.34-.2l3.056-7.86Zm5.305-3.44Zm-2.7-.76h3.469c1.186 0 1.835 1.385 1.075 2.297L5.207 20.856c-1.015 1.219-2.955.075-2.38-1.404l2.42-6.224h-3.82c-1.185 0-1.834-1.378-1.08-2.291L8.955.517c.97-1.175 2.852-.175 2.422 1.286L9.605 7.828Z"
            fill="#fff"
          />
        </g>
      )}
    </svg>
  )
}
