import { useEffect } from "react"
import { Outlet, ScrollRestoration } from "react-router-dom"
import { CurrentUserProvider } from "~/auth/CurrentUserContext"
import { useCommunity } from "~/community/useCommunity"
import { NewFeedPostProvider } from "~/feed/NewFeedPostContext"
import { PostReadProvider } from "~/feed/PostReadContext"
import { ScreenVariantsProvider } from "~/screens/ScreenVariantsProvider"
import { TiersProvider } from "~/tiers/TiersProvider"
import { ConfirmProvider } from "~/ui/Confirm"

export const RootLayout = () => {
  const community = useCommunity()

  useEffect(() => {
    if (community.darkMode) {
      document.body.classList.add("dark")
    }
    return () => {
      document.body.classList.remove("dark")
    }
  }, [community.darkMode])

  return (
    <ScreenVariantsProvider>
      <TiersProvider>
        <CurrentUserProvider>
          <ConfirmProvider>
            <PostReadProvider>
              <NewFeedPostProvider>
                <Outlet />
                <ScrollRestoration />
              </NewFeedPostProvider>
            </PostReadProvider>
          </ConfirmProvider>
        </CurrentUserProvider>
      </TiersProvider>
    </ScreenVariantsProvider>
  )
}
