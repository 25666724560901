import { useQuery } from "@apollo/client"
import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import copy from "copy-to-clipboard"
import { Plus, Variable } from "lucide-react"
import { useMemo, useRef } from "react"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import {
  ScreenEnum,
  ScreenVariant_AdminFragment,
} from "~/__generated__/graphql"
import { AdminHeader } from "~/admin/AdminHeader"
import { AdminTable } from "~/admin/users/UsersTable"
import { formatDate } from "~/common/formatDate"
import {
  adminScreenVariantEditPath,
  adminScreenVariantNewPath,
  applicationLandingPath,
} from "~/common/paths"
import { cn } from "~/lib/utils"
import { Badge } from "~/ui/badge"
import { Button } from "~/ui/button"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/ui/context-menu"
import { CopyToClipboard } from "~/ui/CopyToClipboard"
import { TableRow, TableCell } from "~/ui/table"
import { Muted, typographyVariants } from "~/ui/typography"

const HEADERS = [
  { label: "Screen" },
  { label: "Variant Identifier" },
  { label: "URL" },
  { label: "Active" },
  { label: "Created At" },
  { label: "Updated At" },
]

export const AdminScreenVariantsScreen = () => {
  const { data, loading } = useQuery(SCREEN_VARIANTS_QUERY_DOCUMENT)
  const navigate = useNavigate()

  const screenVariants = data?.screenVariants.nodes

  return (
    <>
      <AdminHeader title="Screen Variants" Icon={Variable}>
        <Button onClick={() => navigate(adminScreenVariantNewPath.pattern)}>
          <Plus size={16} className="mr-2" /> Create Screen Variant
        </Button>
      </AdminHeader>

      <AdminTable
        items={screenVariants || []}
        loading={loading}
        headers={HEADERS}
      >
        {(screenVariant) => (
          <ScreenVariantRow
            key={screenVariant.id}
            screenVariant={screenVariant}
          />
        )}
      </AdminTable>
    </>
  )
}

const ScreenVariantRow = ({
  screenVariant,
}: {
  screenVariant: ScreenVariant_AdminFragment
}) => {
  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)
  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }
  const navigate = useNavigate()
  const { t: screens } = useTranslation("screens")

  const briefUrl = useMemo(() => {
    const screen = screenVariant.screen
    const variantIdentifier = screenVariant.variantIdentifier

    let url = ""
    if (screen === ScreenEnum.ApplicationLandingScreen) {
      url = applicationLandingPath.pattern
    }

    if (!variantIdentifier) {
      return url
    }

    return `${url}?v=${variantIdentifier}`
  }, [screenVariant])

  const url = useMemo(() => {
    const baseUrl = window.location.origin
    return [baseUrl, briefUrl].join("")
  }, [briefUrl])

  const copyToClipboard = () => {
    copy(url)
    toast.success("Copied to clipboard")
  }

  return (
    <ContextMenu>
      <ContextMenuContent>
        <ContextMenuItem
          onClick={() =>
            navigate(
              adminScreenVariantEditPath({ screenVariantId: screenVariant.id! })
            )
          }
          className="cursor-pointer"
        >
          Edit Screen Variant
        </ContextMenuItem>
        <ContextMenuItem onClick={copyToClipboard} className="cursor-pointer">
          Copy URL
        </ContextMenuItem>
      </ContextMenuContent>
      <ContextMenuTrigger asChild>
        <TableRow ref={contextMenuTriggerRef} className="screenVariant">
          <TableCell>
            <div className="flex flex-col gap-1">
              <div>{screens(screenVariant.screen)}</div>
              <div>
                <CopyToClipboard
                  text={url}
                  className={cn(
                    "max-w-64 truncate",
                    typographyVariants({ variant: "muted" })
                  )}
                >
                  {briefUrl}
                </CopyToClipboard>
              </div>
            </div>
          </TableCell>
          <TableCell>
            {screenVariant.variantIdentifier || <Muted>(default)</Muted>}
          </TableCell>
          <TableCell>
            <Badge
              variant={screenVariant.active ? "default" : "secondaryOutline"}
            >
              {screenVariant.active ? "Yes" : "No"}
            </Badge>
          </TableCell>
          <TableCell>{formatDate(screenVariant.createdAt)}</TableCell>
          <TableCell>{formatDate(screenVariant.updatedAt)}</TableCell>
          <TableCell>
            <Button variant="ghost" size="icon" onClick={triggerContextMenu}>
              <DotsHorizontalIcon />
            </Button>
          </TableCell>
        </TableRow>
      </ContextMenuTrigger>
    </ContextMenu>
  )
}

gql(`
  fragment ScreenVariant_Admin on ScreenVariant {
    id
    screen
    variantIdentifier
    overrides
    active
    createdAt
    updatedAt
  }
`)

export const SCREEN_VARIANTS_QUERY_DOCUMENT = gql(`
  query ScreenVariants($first: Int, $after: String) {
    screenVariants(first: $first, after: $after) {
      nodes {
        ...ScreenVariant_Admin
      }
    }
  }
`)
