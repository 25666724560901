import { useCommunity } from "~/community/useCommunity"
import { ProcessingCTAs } from "./ProcessingCTAs"

export const Rejected = () => {
  const community = useCommunity()
  const copy = community.applicationProcessingCopy || {}

  return (
    <div className="flex items-center flex-col">
      <h1 className="text-4xl mb-4 font-serif text-center">
        {copy.sorryHeading}
      </h1>
      <div className="font-sans font-normal tracking-wide mb-4 text-center space-y-4">
        <p>{copy.sorrySubtext1}</p>
        <p>{copy.sorrySubtext2}</p>
        {copy.sorryStrongSubtext && (
          <p>
            <strong>{copy.sorryStrongSubtext}</strong>
          </p>
        )}
      </div>
      <ProcessingCTAs />
    </div>
  )
}
