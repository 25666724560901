import { Namespace, ParseKeys, TOptions, TypeOptions } from "i18next"
import { useMemo } from "react"
import { Trans, TransProps } from "react-i18next"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { CommunityName } from "~/community/CommunityName"
import { CreatorSignature } from "~/community/CreatorSignature"
import { useCommunity } from "~/community/useCommunity"
import { Alert } from "~/ui/alert"
import { Muted } from "~/ui/typography"

type _DefaultNamespace = TypeOptions["defaultNS"]

export function Translation<
  Key extends ParseKeys<Ns, TOpt, KPrefix>,
  Ns extends Namespace = _DefaultNamespace,
  KPrefix = undefined,
  TContext extends string | undefined = undefined,
  TOpt extends TOptions & { context?: TContext } = { context: TContext },
  E = React.HTMLProps<HTMLDivElement>,
>({
  values: valuesFromProps,
  components: componentsFromProps,
  ...props
}: TransProps<Key, Ns, KPrefix, TContext, TOpt, E>) {
  const { currentUser } = useCurrentUserMaybe()
  const community = useCommunity()

  const values = useMemo(() => {
    if (!currentUser) {
      return valuesFromProps || {}
    }

    return {
      ...currentUser,
      communityName: community.name,
      ...valuesFromProps,
    }
  }, [currentUser, valuesFromProps, community])

  const components = useMemo(() => {
    return {
      ...(componentsFromProps || {}),
      Alert: <Alert />,
      AlertHighlight: <Alert variant="highlight" />,
      CommunityName: <CommunityName />,
      CreatorSignature: <CreatorSignature />,
      Muted: <Muted />,
      // eslint-disable-next-line jsx-a11y/heading-has-content
      h5: <h5 className="text-sm font-semibold" />,
      li: <li className="font-medium" />,
      ul: <ul className="flex flex-col gap-1" />,
    }
  }, [componentsFromProps])

  return <Trans values={values} components={components} {...props} />
}
