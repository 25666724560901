import { Route } from "react-router-dom"
import {
  applicationLandingPath,
  applicationFitWaitingScreenPath,
  applicationMostExcitedAboutPath,
} from "~/common/paths"
import { ApplicationLayout } from "./layouts/ApplicationLayout"
import { ApplicationLandingScreen } from "./screens/ApplicationLandingScreen"
import { ABFitWaitingScreen } from "./screens/ABFitWaitingScreen"
import { RequireUserSignedOut } from "~/layouts/RequireUserSignedOut"
import { RequireUserOnboarding } from "~/layouts/RequireUserOnboarding"
import { ApplicationMostExcitedAboutScreen } from "./screens/MostExcitedAboutScreen"
import { RequireUserSignedIn } from "~/layouts/RequireUserSignedIn"

export const useApplicationRoutes = () => {
  return (
    <Route element={<ApplicationLayout />}>
      <Route element={<RequireUserSignedOut />}>
        <Route
          path={applicationLandingPath.pattern}
          element={<ApplicationLandingScreen />}
        />
      </Route>

      <Route element={<RequireUserOnboarding />}>
        <Route
          path={applicationFitWaitingScreenPath.pattern}
          element={<ABFitWaitingScreen />}
        />
      </Route>
      {/* TODO: This probably needs to go somewhere else - but need it for testing for now */}
      <Route element={<RequireUserSignedIn />}>
        <Route
          path={applicationMostExcitedAboutPath.pattern}
          element={<ApplicationMostExcitedAboutScreen />}
        />
      </Route>
    </Route>
  )
}
