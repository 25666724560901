import { Hammer } from "lucide-react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import { ScreenVariantCreateInput } from "~/__generated__/graphql"
import { AdminHeader } from "~/admin/AdminHeader"
import { ScreenVariantForm } from "~/admin/ScreenVariantForm"
import { adminScreenVariantsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"

export const AdminScreenVariantNewScreen = () => {
  const [runCreateScreenVariant] = useSafeMutation(
    SCREEN_VARIANT_CREATE_MUTATION
  )
  const navigate = useNavigate()

  const handleSubmit = async (data: ScreenVariantCreateInput) => {
    const response = await runCreateScreenVariant({
      variables: {
        input: data,
      },
    })

    const { data: responseData, errors } = response

    if (!errors && responseData?.screenVariantCreate?.screenVariant) {
      toast.success("Screen variant created.")
      navigate(adminScreenVariantsPath.pattern)
    }

    return response
  }

  return (
    <div className="pb-12">
      <AdminHeader title="New Screen Variant" subtitle="" Icon={Hammer} />

      <ScreenVariantForm onSubmit={handleSubmit} />
    </div>
  )
}

const SCREEN_VARIANT_CREATE_MUTATION = gql(`
  mutation ScreenVariantCreate($input: ScreenVariantCreateInput!) {
    screenVariantCreate(input: $input) {
      screenVariant {
        ...ScreenVariant_Admin
      }
    }
  }
`)
