import { useQuery } from "@apollo/client"
import { Users } from "lucide-react"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { GroupCreateInput } from "~/__generated__/graphql"
import { AdminHeader } from "~/admin/AdminHeader"
import { GroupForm } from "~/admin/GroupForm"
import { adminGroupsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"

export const AdminGroupEditScreen = () => {
  const [runUpdateGroup] = useSafeMutation(GROUP_UPDATE_MUTATION)
  const navigate = useNavigate()
  const { id } = useParams()
  const { data, loading } = useQuery(GROUP_QUERY_DOCUMENT, {
    variables: { id: id! },
  })
  const group = data?.group

  const handleSubmit = async (data: GroupCreateInput) => {
    const response = await runUpdateGroup({
      variables: {
        input: {
          ...data,
          groupId: id!,
        },
      },
    })

    const { data: responseData, errors } = response

    if (errors) {
      displayErrors(errors)
    } else if (responseData?.groupUpdate?.group) {
      toast.success(
        "Group updated. Please allow a few minutes for the group to populate."
      )
      navigate(adminGroupsPath.pattern)
    }

    return response
  }

  if (loading || !group) return null

  return (
    <>
      <AdminHeader
        title="Dynamic Group"
        subtitle="Dynamic groups will automatically include new members who fit the group criteria"
        Icon={Users}
      />

      <GroupForm
        onSubmit={handleSubmit}
        initialValues={{
          slug: group.slug,
          description: group.description || "",
          active: group.active,
          conditions: group.automations[0].conditions,
        }}
      />
    </>
  )
}

const GROUP_QUERY_DOCUMENT = gql(`
  query Group($id: ID!) {
    group(id: $id) {
      id
      slug
      description
      groupType
      active

      automations {
        id
        name
        category
        invertConditions
        conditions
      }
    }
  }
`)

const GROUP_UPDATE_MUTATION = gql(`
  mutation GroupUpdate($input: GroupUpdateInput!) {
    groupUpdate(input: $input) {
      group {
        id
      }
    }
  }
`)
