import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { Checkbox } from "~/ui/checkbox"
import { cn } from "~/lib/utils"

export const CheckboxField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  required,
  label,
  labelClassName,
  inputClassName,
  containerClassName,
  disabled = false,
}: {
  label: JSX.Element
  labelClassName?: string
  inputClassName?: string
  containerClassName?: string
  description?: JSX.Element
  required?: boolean
  control: Control<TFieldValues> | undefined
  name: TName
  disabled?: boolean
}) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem
          className={cn(
            "flex flex-row items-center justify-center space-x-3 space-y-0 rounded-md border border-mercury p-4",
            containerClassName
          )}
        >
          <FormControl>
            <Checkbox
              checked={field.value}
              onCheckedChange={field.onChange}
              className={inputClassName}
              disabled={disabled}
            />
          </FormControl>
          <div className="space-y-1 leading-none">
            <FormLabel className={labelClassName} required={required}>
              {label}
            </FormLabel>
            {description && <FormDescription>{description}</FormDescription>}
            <FormMessage />
          </div>
        </FormItem>
      )}
    />
  )
}
