import { Control, useFormContext } from "react-hook-form"
import { SelectField } from "~/components/forms/SelectField"
import { TextField } from "~/components/forms/TextField"
import { FormValues } from "../ApplicationLandingScreen"
import { cn } from "~/lib/utils"
import { Translation } from "~/common/Translation"
import { camelCase } from "change-case"
import { CommunitySlug, ScreenEnum } from "~/__generated__/graphql"
import { useCommunity } from "~/community/useCommunity"
import { Coffee } from "lucide-react"

interface MadlibsQuestionsProps {
  control: Control<FormValues>
}

interface PillButtonProps {
  value: string
  onClick: (value: string) => void
  options: { label: string; value: string }[]
}

const QUESTIONS: Record<
  CommunitySlug,
  {
    yearsOfExperience: { label: string; value: string }[]
    cupsOfCoffee?: { label: string; value: string }[]
    jobFunction?: { label: string; value: string }[]
    manageContribute?: { label: string; value: string }[]
    teamSize?: { label: string; value: string }[]
    organizationType?: { label: string; value: string }[]
    business?: { label: string; value: string }[]
  }
> = {
  [CommunitySlug.Safespace]: {
    yearsOfExperience: [
      { label: "0 - 5", value: "0-5" },
      { label: "6 - 10", value: "6-10" },
      { label: "10+", value: "10+" },
    ],
    jobFunction: [
      {
        label: "Compensation & Benefits",
        value: "Compensation & Benefits",
      },
      { label: "Compliance & Legal", value: "Compliance & Legal" },
      { label: "Employee Engagement", value: "Employee Engagement" },
      {
        label: "Employee Training & Development",
        value: "Employee Training & Development",
      },
      { label: "Finance & Analytics", value: "Finance & Analytics" },
      {
        label: "HR Strategy & Organization Development",
        value: "HR Strategy & Organization Development",
      },
      { label: "Payroll Admin", value: "Payroll Admin" },
      {
        label: "Recruitment & Talent Acquisition",
        value: "Recruitment & Talent Acquisition",
      },
      { label: "Other", value: "Other" },
    ],
    manageContribute: [
      { label: "Manage", value: "manage" },
      { label: "Contribute to", value: "contribute_to" },
      { label: "Manage & Contribute to", value: "manage_and_contribute_to" },
    ],
    teamSize: [
      { label: "1", value: "1" },
      { label: "2-5", value: "2-5" },
      { label: "6-20", value: "6-20" },
      { label: "20+", value: "20+" },
    ],
  },
  [CommunitySlug.Boardroom]: {
    yearsOfExperience: [
      { label: "0 - 3", value: "0-3" },
      { label: "4 - 10", value: "4-10" },
      { label: "10+", value: "10+" },
    ],
    jobFunction: [
      { label: "Consulting", value: "Consulting" },
      { label: "Corporate Development", value: "Corporate Development" },
      { label: "Data & Analytics", value: "Data & Analytics" },
      { label: "Direct Patient Care", value: "Direct Patient Care" },
      { label: "Engineering", value: "Engineering" },
      { label: "Finance", value: "Finance" },
      { label: "Higher Education", value: "Higher Education" },
      { label: "Human Resources", value: "Human Resources" },
      { label: "Information Technology", value: "Information Technology" },
      { label: "Legal", value: "Legal" },
      { label: "Marketing", value: "Marketing" },
      { label: "Media", value: "Media" },
      { label: "Operations", value: "Operations" },
      { label: "Public Relations", value: "Public Relations" },
      { label: "Regulartory Affairs", value: "Regulartory Affairs" },
      { label: "Research & Development", value: "Research & Development" },
      {
        label: "Sales & Business Development",
        value: "Sales & Business Development",
      },
      { label: "Strategy", value: "Strategy" },
      { label: "Supply Chain Management", value: "Supply Chain Management" },
    ],
    organizationType: [
      { label: "Consulting Firm", value: "Consulting Firm" },
      {
        label: "Durable Medical Equipment",
        value: "Durable Medical Equipment",
      },
      { label: "Government Health Agency", value: "Government Health Agency" },
      { label: "Health Tech Startup", value: "Health Tech Startup" },
      {
        label: "Hospital or Health System",
        value: "Hospital or Health System",
      },
      { label: "Life Science Company", value: "Life Science Company" },
      { label: "Medical Device Company", value: "Medical Device Company" },
      { label: "Payor Org", value: "Payor Org" },
      { label: "Pharmacy & Retailer", value: "Pharmacy & Retailer" },
      { label: "Provider Org", value: "Provider Org" },
      { label: "Telehealth Provider", value: "Telehealth Provider" },
      { label: "Venture Capital Firm", value: "Venture Capital Firm" },
      { label: "Other", value: "Other" },
    ],
  },
  [CommunitySlug.Marketingland]: {
    yearsOfExperience: [
      { label: "0 - 5", value: "0-5" },
      { label: "6 - 10", value: "6-10" },
      { label: "10+", value: "10+" },
    ],
    organizationType: [
      { label: "at a brand", value: "at a brand" },
      { label: "at an agency", value: "at an agency" },
      {
        label: "as Freelancer or Consultant",
        value: "as Freelancer or Consultant",
      },
    ],
    business: [
      { label: "B2B", value: "B2B" },
      { label: "B2C", value: "B2C" },
    ],
    teamSize: [
      { label: "just me!", value: "just me!" },
      { label: "2 - 5", value: "2-5" },
      { label: "6 - 20", value: "6-20" },
      { label: "21+", value: "21+" },
    ],
    cupsOfCoffee: [
      { label: "0 cups", value: "0 cups" },
      { label: "1 cup", value: "1 cup" },
      { label: "2 cups", value: "2 cups" },
      { label: "3 cups", value: "3 cups" },
      { label: "4 cups", value: "4 cups" },
      { label: "5+ cups", value: "5+ cups" },
    ],
  },
}

const PillButton = ({ value, onClick, options }: PillButtonProps) => {
  return (
    <div className="inline-block">
      <div className="flex gap-2 items-center">
        {options.map((option) => (
          <button
            type="button"
            key={option.value}
            onClick={() => onClick(option.value)}
            className={cn(
              "bg-white border border-slate-200 hover:border-highlight cursor-pointer rounded-full py-2 px-6 text-xs text-center",
              value === option.value &&
                "bg-highlight text-white border-highlight"
            )}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  )
}

const CoffeeButton = ({ value, onClick, options }: PillButtonProps) => {
  return (
    <div className="inline-block px-2">
      <div className="flex gap-1 items-center py-2">
        {options.map((option, index) => {
          const selectedIndex = options.findIndex((opt) => opt.value === value)
          const isHighlighted = selectedIndex >= index

          return (
            <button
              type="button"
              key={option.value}
              onClick={() => onClick(option.value)}
              className={cn(
                "cursor-pointer",
                isHighlighted && "text-highlight"
              )}
            >
              <Coffee className="w-5 h-5" />
            </button>
          )
        })}
      </div>
    </div>
  )
}

const YearsOfExperienceInput = ({
  value,
  onClick,
}: Pick<PillButtonProps, "value" | "onClick">) => {
  const community = useCommunity()

  return (
    <PillButton
      value={value}
      onClick={onClick}
      options={QUESTIONS[community.slug].yearsOfExperience}
    />
  )
}

const CupsOfCoffeeInput = ({
  value,
  onClick,
}: Pick<PillButtonProps, "value" | "onClick">) => {
  const community = useCommunity()

  return (
    <CoffeeButton
      value={value}
      onClick={onClick}
      options={QUESTIONS[community.slug].cupsOfCoffee ?? []}
    />
  )
}

export const MadlibsQuestions = ({ control }: MadlibsQuestionsProps) => {
  const community = useCommunity()
  const { setValue, watch } = useFormContext()
  const yearsOfExperience = watch("yearsOfExperience")
  const setYearsOfExperience = (value: string) => {
    setValue("yearsOfExperience", value)
  }
  const cupsOfCoffee = watch("cupsOfCoffee")
  const setCupsOfCoffee = (value: string) => {
    setValue("cupsOfCoffee", value)
  }
  return (
    <div className="leading-[3rem]">
      <Translation
        ns={camelCase(ScreenEnum.ApplicationLandingScreen)}
        i18nKey="madlibs"
        components={{
          YearsOfExperienceInput: (
            <YearsOfExperienceInput
              value={yearsOfExperience}
              onClick={setYearsOfExperience}
            />
          ),
          CupsOfCoffeeInput: (
            <CupsOfCoffeeInput value={cupsOfCoffee} onClick={setCupsOfCoffee} />
          ),
          JobFunctionSelect: (
            <SelectField
              placeholder="Select your primary focus area"
              containerClassName="inline-block w-fit space-y-0"
              label=""
              control={control}
              name="jobFunction"
              selectEntries={QUESTIONS[community.slug].jobFunction ?? []}
            />
          ),
          JobFunctionInput: (
            <TextField
              containerClassName="inline-block space-y-0"
              control={control}
              name="jobFunction"
              placeholder="Your experience"
              label=""
            />
          ),
          JobTitleInput: (
            <TextField
              containerClassName="inline-block space-y-0"
              control={control}
              name="jobTitle"
              placeholder="Enter your job title"
              label=""
            />
          ),
          RoleInput: (
            <SelectField
              placeholder="Select role"
              containerClassName="inline-block w-fit space-y-0"
              label=""
              control={control}
              name="manageContribute"
              selectEntries={QUESTIONS[community.slug].manageContribute ?? []}
            />
          ),
          TeamSizeInput: (
            <SelectField
              placeholder="Select team size"
              containerClassName="inline-block w-fit space-y-0"
              label=""
              control={control}
              name="teamSize"
              selectEntries={QUESTIONS[community.slug].teamSize ?? []}
            />
          ),
          OrganizationTypeInput: (
            <SelectField
              placeholder="Select organization type"
              containerClassName="inline-block w-fit space-y-0"
              label=""
              control={control}
              name="organizationType"
              selectEntries={QUESTIONS[community.slug].organizationType ?? []}
            />
          ),
          BusinessSelect: (
            <SelectField
              placeholder="Select business type"
              containerClassName="inline-block w-fit space-y-0"
              label=""
              control={control}
              name="business"
              selectEntries={QUESTIONS[community.slug].business ?? []}
            />
          ),
        }}
      />
    </div>
  )
}
