import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"

export const useFetchLead = (id?: string | null) => {
  const { data, loading, error } = useQuery(LEAD_QUERY_DOCUMENT, {
    variables: { id: id || "" },
    skip: !id,
  })

  return {
    lead: data?.lead,
    loading,
    error,
  }
}

const LEAD_QUERY_DOCUMENT = gql(`
  query Lead($id: ID!) {
    lead(id: $id) {
      id
      name
      email
    }
  }
`)
