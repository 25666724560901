import { useEffect, useRef, useState, useCallback } from "react"
import {
  Post_CardFragment,
  Article_CardFragment,
} from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"
import { getMetaVar } from "../common/getMetaVar"

export enum ImpressionContentContextEnum {
  Standalone = "standalone",
  Embed = "embed",
}

type ContentType = {
  type: "post" | "article"
  content: Post_CardFragment | Article_CardFragment
  context?: ImpressionContentContextEnum
}

export const useImpressions = ({
  inView,
  content,
  enabled,
  elementRef,
}: {
  inView: boolean
  content: ContentType
  enabled: boolean
  elementRef: React.RefObject<HTMLElement>
}) => {
  const impressionTrackingEnabled = getMetaVar("track-impressions") === "true"
  const env = getMetaVar("environment")
  const impressionRef = useRef(false)
  const { logEventDeferred } = useLogEvent()

  const [impressionsReady, setImpressionsReady] = useState(false)
  const [impressionMade, setImpressionMade] = useState(false)

  const getPositionMetrics = useCallback((element: HTMLElement | null) => {
    if (!element) {
      return { pixelDepth: null }
    }

    const rect = element.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const elementTop = rect.top + scrollTop

    return {
      pixelDepth: Math.round(elementTop),
    }
  }, [])

  useEffect(() => {
    const interval = setTimeout(() => {
      setImpressionsReady(true)
    }, 750)

    return () => clearTimeout(interval)
  }, [])

  useEffect(() => {
    if (!impressionsReady) return
    if (env !== "development" && !impressionTrackingEnabled) return
    if (!enabled || impressionRef.current) return
    if (!inView) return

    if (content.type === "post") {
      const post = content.content as Post_CardFragment
      const eventType = post.isReply
        ? AhoyEventTypeEnum.ReplyImpression
        : AhoyEventTypeEnum.PostImpression

      const { pixelDepth } = getPositionMetrics(elementRef.current)

      logEventDeferred(
        eventType,
        {
          post_id: post.id,
          reaction_count: post.reactionEntries.length,
          replies_count: post.repliesCount,
          nested_replies_count: post.nestedRepliesCount,
          share_count: post.shareCount,
          context: content.context?.toString(),
          pixel_depth: pixelDepth,
        },
        impressionRef
      )
    } else {
      const article = content.content as Article_CardFragment

      const { pixelDepth } = getPositionMetrics(elementRef.current)

      logEventDeferred(
        AhoyEventTypeEnum.ArticleCardImpression,
        {
          article_id: article.id,
          is_premium: article.premium,
          revision_id: article.approvedRevision?.id,
          content_type: article.approvedRevision?.contentType,
          content_topic: article.approvedRevision?.tag?.name,
          is_featured: article.featuredUntil !== null,
          context: content.context?.toString(),
          pixel_depth: pixelDepth,
        },
        impressionRef
      )
    }

    // A state hook is used here even though the `impressionRef` will mutate.
    // This is so consumer's can still be aware of impressions made and react
    // accordingly.
    setImpressionMade(true)
  }, [
    inView,
    content,
    enabled,
    logEventDeferred,
    env,
    impressionTrackingEnabled,
    getPositionMetrics,
    elementRef,
    impressionsReady,
    setImpressionMade,
  ])

  return { impressionMade }
}
