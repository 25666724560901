import { format } from "date-fns"
import {
  AhoyEventTypeEnum,
  Bookmark_CardFragment,
} from "~/__generated__/graphql"
import { ArticleCard } from "~/ui/ArticleCard"
import { PostCard } from "~/feed/PostCard"
import { CardHeader } from "~/ui/card"

import BookmarkFilled from "~/images/icons/bookmark-filled.svg?react"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCallback } from "react"

export const BookmarkCard = ({
  bookmark,
  query,
}: {
  bookmark: Bookmark_CardFragment
  query?: string
}) => {
  const { logEvent } = useLogEvent()

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (e.target instanceof HTMLAnchorElement) {
        logEvent(AhoyEventTypeEnum.BookmarkLinkClicked, {
          url_clicked: e.target.href,
        })
      }
    },
    [logEvent]
  )

  if (bookmark.isDestroyed) {
    // if the record was unbookmarked, we don't want to render anything
    return
  }

  const header = (
    <CardHeader>
      <div className="flex flex-row justify-center items-center gap-2">
        <BookmarkFilled />
        <div className="text-2xs">
          <b>Bookmarked</b> {format(bookmark.createdAt, "MMM d, yyyy")}
        </div>
      </div>
    </CardHeader>
  )

  return (
    <div onClick={handleClick}>
      {bookmark.post && (
        <PostCard
          post={bookmark.post}
          header={header}
          query={query}
          cardClickable
          trackImpressions
        />
      )}
      {bookmark.article && (
        <ArticleCard
          article={bookmark.article}
          header={header}
          query={query}
          wideVersion
          trackImpressions
        />
      )}
    </div>
  )
}
