import { useQuery } from "@apollo/client"
import { RenderFn } from "editorjs-blocks-react-renderer"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { PostCard } from "~/feed/PostCard"
import { ArticleCard } from "~/ui/ArticleCard"
import { VideoSection } from "~/feed/VideoSection"
import React from "react"
import { getURL } from "~/components/FormattedContent"

interface LinkBlockMetaData {
  description?: string
  title?: string
  image?: {
    url?: string
  }
  post_id?: string
  article_id?: string
  type?: "video" | "post" | "link" | "article"
}

export interface LinkBlockData {
  meta: LinkBlockMetaData
  link: string
}

export const LinkRenderer: RenderFn<LinkBlockData> = ({ data }) => {
  let renderer: React.ReactNode | null = null

  if (data.meta.type === "post" && data.meta.post_id) {
    renderer = <PostLinkRenderer data={data} />
  } else if (data.meta.type === "article" && data.meta.article_id) {
    renderer = <ArticleLinkRenderer data={data} />
  } else if (data.meta.type === "video") {
    renderer = <VideoLinkRenderer data={data} />
  } else {
    renderer = <StandardLinkRenderer data={data} />
  }

  return <div className="editorjs-link-block">{renderer}</div>
}

const StandardLinkRenderer = ({ data }: { data: LinkBlockData }) => {
  const host = getURL(data.link)?.hostname

  if (!host) return null
  return (
    <a
      href={data.link}
      className="mb-6 p-6 flex items-start rounded-2xl border border-slate-200 !no-underline"
    >
      <div className="flex-1">
        {data.meta.title && data.meta.title.length > 0 && (
          <div className="text-black font-semibold tracking-wide mb-2 text-sm">
            {data.meta.title}
          </div>
        )}
        {data.meta.description && data.meta.description.length > 0 && (
          <div className="text-black mb-2 text-sm">{data.meta.description}</div>
        )}
        <div className="text-xs text-gray-500">{host}</div>
      </div>

      {data.meta.image?.url && (
        <img
          src={data.meta.image.url}
          alt="preview"
          className="w-[85px] h-[85px] ml-5 rounded-2xl object-cover"
        />
      )}
    </a>
  )
}

const VideoLinkRenderer = ({ data }: { data: LinkBlockData }) => {
  return (
    <div className="max-w-[600px] mb-6">
      <VideoSection videoUrl={data.link} />
    </div>
  )
}

const PostLinkRenderer = ({ data }: { data: LinkBlockData }) => {
  const postId = data.meta.post_id
  invariant(postId)

  const { data: postData } = useQuery(POST_QUERY_DOCUMENT, {
    variables: { postId },
  })

  if (!postData) return null

  return (
    <div className="mb-6">
      <PostCard
        post={postData.post}
        hidePostMenu
        hidePostActions
        cardClickable
      />
    </div>
  )
}

const ArticleLinkRenderer = ({ data }: { data: LinkBlockData }) => {
  const articleId = data.meta.article_id
  invariant(articleId)

  const { data: articleData } = useQuery(ARTICLE_QUERY_DOCUMENT, {
    variables: { articleId },
  })

  const article = articleData?.article

  if (!article) return null

  return (
    <div className="mb-6">
      <ArticleCard
        wideVersion={true}
        authorNamesTruncation={1000}
        titleTruncation={1000}
        titleLineLimit={3}
        descriptionTruncation={1000}
        article={article}
        trackImpressions
      />
    </div>
  )
}

const ARTICLE_QUERY_DOCUMENT = gql(`
  query EmbeddedContentArticle($articleId: ID!) {
    article(articleId: $articleId) {
      ...Article_Card
    }
  }
`)

const POST_QUERY_DOCUMENT = gql(`
  query EmbeddedContentPost($postId: ID!) {
    post(postId: $postId) {
      ...Post_Card
    }
  }
`)
